<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div class="w-lg-500px p-10">
                        <form class="form w-100" id="kt_sign_in_form">
                            <div style="text-align: center;">
                                <img src="assets/media/avatars/success-icon.png" alt="" style="width: 15%;" /> 
                            </div>
                            <div class="text-center mb-11">
                                <h2 class="text-dark fw-bolder mb-3">Your account has been deleted successfully.</h2>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>    