import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { MasterSettingsService } from "../services/masterSettings.service";
import { MasterSettings } from "../models/masterSettings";

@Component({
	selector: 'app-master-settings',
	templateUrl: './master-settings.component.html',
	styleUrls: ['./master-settings.component.css']
})
export class MasterSettingsComponent implements OnInit {

	updateMasterSettingsForm = new FormGroup({
		hospital: new FormControl,
		blood_bank: new FormControl,
		path_lab: new FormControl,
		id: new FormControl
	});

	get f() {
		return this.updateMasterSettingsForm.controls;
	}
	errorMessage = '';
	successMessage = '';

	constructor(
		private MasterSettingsService: MasterSettingsService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.getMasterSettings();
	}

	getMasterSettings(): void {
		this.MasterSettingsService.getMasterSettings().subscribe(
		(data) => {
			if (data.status == 200) {
				this.updateMasterSettingsForm.controls["hospital"].patchValue(data.data[0].hospital);
				this.updateMasterSettingsForm.controls["blood_bank"].patchValue(data.data[0].blood_bank);
				this.updateMasterSettingsForm.controls["path_lab"].patchValue(data.data[0].path_lab);
				this.updateMasterSettingsForm.controls["id"].setValue(data.data[0].id);
			}
		}
		);
	}

	updateMasterSettings(): void {
		this.errorMessage = '';
		let hospital = this.updateMasterSettingsForm.value.hospital;
		let blood_bank = this.updateMasterSettingsForm.value.blood_bank;
		let path_lab = this.updateMasterSettingsForm.value.path_lab;
		let id = this.updateMasterSettingsForm.value.id;
		hospital = hospital ? 1 : 0;
		blood_bank = blood_bank ? 1 : 0;
		path_lab = path_lab ? 1 : 0;

		this.MasterSettingsService.updateMasterSettings({ hospital, blood_bank, path_lab, id } as MasterSettings)
		.subscribe(
			data => {
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessage = data.message;
				}
			},
			error => {
				this.errorMessage = error;
			}
		);
	}
}
