import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Article } from "../models/article";
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class ArticleService {
    private baseUrl = environment.baseURL + "article/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getArticle(params: any): Observable<Article> {
        return this.http
            .post<Article>(`${this.baseUrl}getArticle`, params, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }

    addArticle(params: Article): Observable<Article> {
        return this.http
            .post<Article>(`${this.baseUrl}createArticle`, params, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }

    getArticleById(articleId: any): Observable<Article> {
        return this.http
            .post<Article>(`${this.baseUrl}getArticleById`, { articleId }, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }

    uploadArticleImage(image: File): Observable<any> {
        const formData = new FormData();
        formData.append('featured_image', image);
        return this.http.post(`${this.baseUrl}uploadArticleImage`, formData);
    }
    
    updateArticle(params: Article): Observable<Article> {
        return this.http
            .post<Article>(`${this.baseUrl}updateArticle`, params, this.getHttpOptions())
            .pipe(map((articleManage) => articleManage));
    }

    deleteArticle(articleId: Article): Observable<Article> {
        return this.http
            .post<Article>(`${this.baseUrl}deleteArticle`, articleId, this.getHttpOptions())
            .pipe(map((articleManage) => articleManage));
    }
}
