import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Amenity } from "../models/amenity";

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class RoleService {
    private roleBaseUrl = environment.baseURL + "role/";

    constructor(private http: HttpClient) { }

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    getRole(): Observable<any> {
        return this.http.get<any>(`${this.roleBaseUrl}getRole`, this.getHttpOptions()).pipe(map(response => response));
    }
}
