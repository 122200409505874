import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Department } from "../models/department";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class DepartmentService {
    private baseUrl = environment.baseURL + "department/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getDepartment(params: any): Observable<Department> {
        return this.http.post<Department>(`${this.baseUrl}getDepartment`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getDepartmentById(departmentId: any): Observable<Department> {
        return this.http.post<Department>(`${this.baseUrl}getDepartmentById`, { departmentId }, this.getHttpOptions()).pipe(map(response => response));
    }

    updateDepartment(department: Department): Observable<Department> {
        return this.http.post<Department>(`${this.baseUrl}updateDepartment`, department, this.getHttpOptions()).pipe(map(response => response));
    }

    addDepartment(department: Department): Observable<Department> {
        return this.http.post<Department>(`${this.baseUrl}createDepartment`, department, this.getHttpOptions()).pipe(map(response => response));
    }

    deleteDepartment(department: Department): Observable<Department> {
        return this.http.post<Department>(`${this.baseUrl}deleteDepartment`, department, this.getHttpOptions()).pipe(map(response => response));
    }

    getAllDepartment(): Observable<Department> {
        return this.http.get<Department>(`${this.baseUrl}getAllDepartment`, this.getHttpOptions()).pipe(map(response => response));
    }
}
