import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ArticleCategoryModel } from "../models/articleCategory";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class ArticleCategoryService {
	
		private getArticleUrl = environment.baseURL + "articleCategory/getArticleCategory";
		private getArticleByIdUrl = environment.baseURL + "articleCategory/getArticleCategoryById";
		private updateArticleUrl = environment.baseURL + "articleCategory/updateArticleCategory";
		private addArticleUrl = environment.baseURL + "articleCategory/createArticleCategory";
		private deleteArticleUrl = environment.baseURL + "articleCategory/deleteArticleCategory";


			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  
			  getArticleApi(params: any): Observable<ArticleCategoryModel> {
				return this.http
				.post<ArticleCategoryModel>(this.getArticleUrl, params, this.httpOptions)
				.pipe(
					map((ArticleList) => {
					return ArticleList;
					})
				);
			}

			getArticleById(articleCategoryId: any): Observable<ArticleCategoryModel> {
				let articleIds = { articleCategoryId: articleCategoryId };
				return this.http
				.post<ArticleCategoryModel>(this.getArticleByIdUrl, articleIds, this.httpOptions)
				.pipe(
					map((articleList) => {
					return articleList;
					})
				);
			}

			updateArticle(articleId: ArticleCategoryModel): Observable<ArticleCategoryModel> {
				return this.http
				.post<ArticleCategoryModel>(this.updateArticleUrl, articleId, this.httpOptions)
				.pipe(
					map((articleManage) => {
					return articleManage;
					})
				);
			}

			addArticleApi(role_id: ArticleCategoryModel): Observable<ArticleCategoryModel> {
				return this.http
				.post<ArticleCategoryModel>(this.addArticleUrl, role_id, this.httpOptions)
				.pipe(
					map((Article) => {
					return Article;
					})
				);
			}

			deleteArticleTypes(articleId: ArticleCategoryModel): Observable<ArticleCategoryModel> {
				return this.http
				.post<ArticleCategoryModel>(this.deleteArticleUrl, articleId, this.httpOptions)
				.pipe(
					map((Manage) => {
					return Manage;
					})
				);
			}
	
	}
