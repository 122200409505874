import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { NewsletterService } from "../services/newsletter.service";

@Component({
	selector: 'app-newsletter-manage',
	templateUrl: './newsletter-manage.component.html',
	styleUrls: ['./newsletter-manage.component.css']
})

export class NewsletterManageComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	closeResult = "";

	newsletterList: any;

	constructor(
		private newsletterService: NewsletterService,
	) { }

	ngOnInit(): void {
		this.getNewsletter();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getNewsletter(): void {
		this.newsletterList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.newsletterService.getNewsletter(dataTablesParameters).subscribe(
					(data) => {
						that.newsletterList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}
}