import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { MasterSettingsModel } from "../models/masterSettings";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class MasterSettingsService {
	
		private updateMasterSettingsUrl = environment.baseURL + "masterSettings/updateMasterSettings";

		private getMasterSettingsUrl = environment.baseURL + "masterSettings/getMasterSettings";


			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  
			  updateMasterApi(Data: MasterSettingsModel): Observable<MasterSettingsModel> {
				return this.http
				.post<MasterSettingsModel>(this.updateMasterSettingsUrl, Data, this.httpOptions)
				.pipe(
					map((UpdateMaster) => {
					return UpdateMaster;
					})
				);
			}

				getMasterApi(): Observable<MasterSettingsModel> {
					return this.http
					.get<MasterSettingsModel>(this.getMasterSettingsUrl, this.httpOptions)
					.pipe(
						map((GetMaster) => {
						return GetMaster;
						})
					);
				}
			
	
	}
