import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BusinessMasterRoutingModule } from "./business-master-routing.module";
import { BusinessMasterComponent } from "./business-master.component";

@NgModule({
  imports: [
    CommonModule,
    BusinessMasterRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BusinessMasterComponent],
  providers: [AuthGuard],
})
export class BusinessMasterModule{}
