import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DeleteAccountThankyouRoutingModule } from './delete-account-thankyou-routing.module';
import { DeleteAccountThankyouComponent } from './delete-account-thankyou.component';

@NgModule({
  imports: [
    CommonModule,
    DeleteAccountThankyouRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [DeleteAccountThankyouComponent]
})
export class DeleteAccountThankyouModule { }
