import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { EmailTemplateService } from "../services/emailTemplate.service";
import { EmailTemplate } from "../models/emailTemplate";

@Component({
	selector: 'app-email-template-manage',
	templateUrl: './email-template-manage.component.html',
	styleUrls: ['./email-template-manage.component.css']
})
export class EmailTemplateManageComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	addEmailTemplateForm = new FormGroup({
		subject: new FormControl("", [Validators.required]),
		alias: new FormControl("", [Validators.required]),
		message: new FormControl("", [Validators.required]),
	});

	updateEmailTemplateForm = new FormGroup({
		subject: new FormControl("", [Validators.required]),
		alias: new FormControl("", [Validators.required]),
		message: new FormControl("", [Validators.required]),
		emailTemplateId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addEmailTemplateForm.controls;
	}

	emailTemplateList: any;
	emailTemplateById: any;
	deleteEmailTemplateId: any;
	errorMessage = "";
	successMessage = "";

	constructor(
		private emailTemplateService: EmailTemplateService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getEmailTemplate();
	}
	
	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getEmailTemplate(): void {
		this.emailTemplateList = [];
		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.emailTemplateService.getEmailTemplate(dataTablesParameters).subscribe(
					(data) => {
						this.emailTemplateList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
		};
	}

	openEmailTemplateAdd(content: any): void {
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}
	
	addEmailTemplate($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let subject = this.addEmailTemplateForm.value.subject.trim();
		let alias = this.addEmailTemplateForm.value.alias.trim();
		let message = this.addEmailTemplateForm.value.message.trim();

		if (!subject || !alias || !message) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.emailTemplateService
			.addEmailTemplate({
				subject,
				alias,
				message,
			} as EmailTemplate)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.addEmailTemplateForm.reset(
							{
								"subject": "",
								"alias": "",
								"message": ""
							}
						);
						document.getElementById("addEmailTemplateModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openEmailTemplateEdit(content: any, emailTemplatelId: any): void {
		this.emailTemplateService.getEmailTemplateById(emailTemplatelId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateEmailTemplateForm.controls["subject"].setValue(data.data.subject);
					this.updateEmailTemplateForm.controls["alias"].setValue(data.data.alias);
					this.updateEmailTemplateForm.controls["message"].setValue(data.data.message);
					this.updateEmailTemplateForm.controls["emailTemplateId"].setValue(data.data.id);
				} else {
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}
	
	updateEmailTemplate($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let subject = this.updateEmailTemplateForm.value.subject.trim();
		let alias = this.updateEmailTemplateForm.value.alias.trim();
		let message = this.updateEmailTemplateForm.value.message.trim();
		let emailTemplateId = this.updateEmailTemplateForm.value.emailTemplateId;

		if (!subject || !alias || !message) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.emailTemplateService
			.updateEmailTemplate({
				subject,
				alias,
				message,
				emailTemplateId
			} as EmailTemplate)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateEmailTemplateForm.reset(
							{
								"subject": "",
								"alias": "",
								"message": "",
								"emailTemplateId": ""
							}
						);
						document.getElementById("updateEmailTemplateModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openEmailTemplateView(content: any, emailTemplateId: any): void {
		this.emailTemplateService.getEmailTemplateById(emailTemplateId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.emailTemplateById = data.data;
				} else {
					this.emailTemplateById = [];
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	openEmailTemplateDelete(content: any, emailTemplateId: any): void {
		this.deleteEmailTemplateId = emailTemplateId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteEmailTemplate(): void {

		let emailTemplateId = this.deleteEmailTemplateId
		if (!emailTemplateId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.emailTemplateService.deleteEmailTemplate({ emailTemplateId } as EmailTemplate).subscribe(
			(data) => {
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteEmailTemplateModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getEmailTemplate();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}
