import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private baseUrl = environment.baseURL + "common/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getProfile(): Observable<User> {
        let userData: any = localStorage.getItem('currentUser');
        let userArray = JSON.parse(userData);
        let userId = userArray.data.id;
        return this.http.post<User>(`${this.baseUrl}profile`, { userId }, this.getHttpOptions()).pipe(map(user => user));
    }

    updateProfile(user: User): Observable<User> {
        return this.http
            .post<User>(`${this.baseUrl}updateProfile`, user, this.getHttpOptions())
            .pipe(map(user => user));
    }

    changePassword(user: User): Observable<User> {
        return this.http
            .post<User>(`${this.baseUrl}changePassword`, user, this.getHttpOptions())
            .pipe(map(user => user));
    }

    getDeviceById(userId: any): Observable<User> {
        return this.http
            .post<User>(`${this.baseUrl}getDeviceDetails`, { user_id: userId }, this.getHttpOptions())
            .pipe(map(userList => userList));
    }

    getSettingsById(userId: any): Observable<User> {
        return this.http
            .post<User>(`${this.baseUrl}getSettingsByUserId`, { user_id: userId }, this.getHttpOptions())
            .pipe(map(userList => userList));
    }
}
