import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HospitalTypesComponent } from './hospital-types.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'hospital_types',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: HospitalTypesComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HospitalTypesRoutingModule { }


