import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Disease } from "../models/disease";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class DiseaseService {
    private baseUrl = environment.baseURL + "disease/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getDisease(params: any): Observable<Disease> {
        return this.http.post<Disease>(`${this.baseUrl}getDisease`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getDiseaseById(diseaseId: any): Observable<Disease> {
        return this.http.post<Disease>(`${this.baseUrl}getDiseaseById`, { diseaseId }, this.getHttpOptions()).pipe(map(response => response));
    }

    addDisease(disease: Disease): Observable<Disease> {
        return this.http.post<Disease>(`${this.baseUrl}createDisease`, disease, this.getHttpOptions()).pipe(map(response => response));
    }

    updateDisease(disease: Disease): Observable<Disease> {
        return this.http.post<Disease>(`${this.baseUrl}updateDisease`, disease, this.getHttpOptions()).pipe(map(response => response));
    }

    deleteDisease(disease: Disease): Observable<Disease> {
        return this.http.post<Disease>(`${this.baseUrl}deleteDisease`, disease, this.getHttpOptions()).pipe(map(response => response));
    }
}
