import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: "app-blood-request-details",
	templateUrl: "./blood-request-details.component.html",
	styleUrls: ["./blood-request-details.component.css"],
})
export class BloodRequestDetailsComponent implements OnInit {
  	
	userId: any;
	userById: any;
	donorRequestList: any;
	donorChats: any;
	
	constructor(
		private bloodRequestService: BloodRequestService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.BloodRequestById(this.userId);
	}

	overviewData = true;
	donorData = false;
	donorReview = false;
	donorChatList = false;
	
	BloodRequestById(userId: any): void {
		this.bloodRequestService.getBloodRequestById(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				this.userById = data.data;
				this.overviewData = true;
				this.donorData = false;
				this.donorReview = false;
				this.donorChatList =false;
			} else {
				this.userById = [];
			}
		},
		(error) => {
		}
		);
	}
	
	donorListView(userId: any): void {
		this.bloodRequestService.getDonorList(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.donorData = true;
					this.donorReview = false;
					this.donorChatList = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
			);
		}
	
	donorRatingReview(userId: any): void {
		this.bloodRequestService.getDonorRatingReview(userId).subscribe(
			(data) => {
				if (data.status == 200){
					this.userById = data.data;
					this.overviewData = false;
					this.donorData = false;
					this.donorReview = true;
					this.donorChatList = false;

				}else {
					this.userById = [];
				}
			},
			(error) => {
			}
			);
		}

	donorChat(userId: any): void {
		this.bloodRequestService.getDonorChatApi(userId).subscribe(
			(data) => {
				if (data.status == 200){
					this.userById = data.data;
					this.overviewData = false;
					this.donorData = false;
					this.donorReview = false;
					this.donorChatList = true;

				}else {
					this.userById = [];
				}
			},
			(error) => {
			}
			);
		}
}
