<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Add Article Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="row ">
		            <div class="col-xl-12">
                        <div class="card card-xl-stretch mb-5 mb-xl-8">
                            <div class="card-body">
                                <div class="card-toolbar">
                                    <form [formGroup]="addArticleForm" (ngSubmit)="addArticleManage($event)" class="form">
                                        <div class="d-flex flex-column scroll-y me-n7 pe-7">
                                            <div class="row mb-5">
                                                <div class="col-md-9 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Title</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="title"  placeholder="Title" (change)="onTitleChange($event)" />
                                                </div>
                                                <div class="col-md-3 fv-row">
                                                    <tbody class="text-gray-600 fw-semibold">
                                                        <label class="required fs-12 fw-semibold mb-2">Category Id</label>
                                                        <select class="form-control form-control-solid" formControlName="category_id" [ngClass]="{'inputError': f.category_id.touched && f.category_id.invalid && f.category_id.errors && f.category_id.errors.required }"
                                                        [ngClass]="{'inputError': formErrorMessages.category_idError }">
                                                            <option value="">Please select category</option>
                                                            <option *ngFor="let category of categoryList;let i = index" [value]="category.id">{{category.name}}</option>
                                                        </select>
                                                    </tbody>
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-9 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Slug</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="slug"  placeholder="Slug" />
                                                </div>
                                                <div class="col-md-3 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Featured Image</label>
                                                    <input type="file" #imageInput (change)="processFile(imageInput)" accept="image/*" class="form-control form-control-solid" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Description</label>
                                                    <input type="hidden" id="description_content" />
                                                    <textarea formControlName="description" id="description" class="form-control form-control-solid"></textarea>
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-3 fv-row">
                                                    <tbody class="text-gray-600 fw-semibold">
                                                        <label class="required fs-12 fw-semibold mb-2">Uploaded By</label>
                                                        <select class="form-control form-control-solid" formControlName="uploaded_by" [ngClass]="{'inputError': f.uploaded_by.touched && f.uploaded_by.invalid && f.uploaded_by.errors && f.uploaded_by.errors.required }"
                                                        [ngClass]="{'inputError': formErrorMessages.uploaded_byError }">
                                                            <option value="">Please select user</option>
                                                            <option *ngFor="let user of userList;let i = index" [value]="user.id">
                                                            {{user.first_name}}
                                                            </option>
                                                        </select>
                                                    </tbody>
                                                </div>
                                                <div class="col-md-3 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Status</label>
                                                    <select class="form-control form-control-solid" formControlName="status"  [ngClass]="{'inputError': f.status.touched && f.status.invalid && f.status.errors && f.status.errors.required }"
                                                    [ngClass]="{'inputError': formErrorMessages.statusError }">
                                                        <option value="" disabled="disabled" selected="selected" >Please select Status</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Tags</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="tags" id="tags-add" placeholder="Tags" data-kt-repeater="tagify" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Meta Title</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="meta_title"  placeholder="Meta Title" [ngClass]="{'inputError': f.meta_title.touched && f.meta_title.invalid && f.meta_title.errors && f.meta_title.errors.required }"
                                                    [ngClass]="{'inputError': formErrorMessages.meta_titleError }" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Meta Keywords</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="meta_keywords"  placeholder="Meta Keywords" [ngClass]="{'inputError': f.meta_keywords.touched && f.meta_keywords.invalid && f.meta_keywords.errors && f.meta_keywords.errors.required }"
                                                    [ngClass]="{'inputError': formErrorMessages.meta_keywordsError }" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Meta Description</label>
                                                    <textarea style="height: 100px; width: 500px; border-style:solid" type="text" class="form-control form-control-solid" formControlName="meta_description"  placeholder="Meta Description" [ngClass]="{'inputError': f.meta_description.touched && f.meta_description.invalid && f.meta_description.errors && f.meta_description.errors.required }"
                                                    [ngClass]="{'inputError': formErrorMessages.meta_descriptionError }"></textarea>
                                                </div>
                                            </div>
                                            <div *ngIf="errorMessage">
                                                <div class="alert alert-danger" role="alert">
                                                    {{errorMessage}}
                                                </div>
                                            </div>
                                            <div *ngIf="successMessage">
                                                <div class="alert alert-success" role="alert">
                                                    {{successMessage}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center pt-15">
                                            <a href="article_manage" class="btn btn-primary primaryBtn">Cancel</a>&nbsp;
                                            <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                                                <span class="indicator-label">Submit</span>
                                                <span class="indicator-progress">Please wait...
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
			            </div>
			        </div>
			    </div>
			</div>
		</div>
	</div>
</div>
