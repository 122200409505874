import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PrivacyPolicyRoutingModule } from './privacy-policy-routing.module';
import { PrivacyPolicyComponent } from './privacy-policy.component';

@NgModule({
  imports: [
    CommonModule,
    PrivacyPolicyRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [PrivacyPolicyComponent]
})
export class PrivacyPolicyModule { }
