import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AmenityManageModel } from "../models/amenityManage";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class AmenityManageService {
	
		private getRoleUrl = environment.baseURL + "role/getRole";
		private getAmenityUrl = environment.baseURL + "amenity/getAmenity";
		private getAmenityByIdUrl = environment.baseURL + "amenity/getAmenityById";
		private updateAmenityUrl = environment.baseURL + "amenity/updateAmenity";
		private addAmenityUrl = environment.baseURL + "amenity/createAmenity";
		private deleteAmenityUrl = environment.baseURL + "amenity/deleteAmenity";


			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}

			  getRoleId(): Observable<AmenityManageModel> {
				return this.http
				.get<AmenityManageModel>(this.getRoleUrl, this.httpOptions)
				.pipe(
					map((Get) => {
						return Get;
					})
				);
			}

	  
			  getAmenityApi(params: any): Observable<AmenityManageModel> {
				return this.http
				.post<AmenityManageModel>(this.getAmenityUrl, params, this.httpOptions)
				.pipe(
					map((AmenityList) => {
					return AmenityList;
					})
				);
			}

			getAmenityById(amenityId: any): Observable<AmenityManageModel> {
				let amenityIds = { amenityId: amenityId };
				return this.http
				.post<AmenityManageModel>(this.getAmenityByIdUrl, amenityIds, this.httpOptions)
				.pipe(
					map((AmenityList) => {
					return AmenityList;
					})
				);
			}

			updateAmenity(amenityId: AmenityManageModel): Observable<AmenityManageModel> {
				return this.http
				.post<AmenityManageModel>(this.updateAmenityUrl, amenityId, this.httpOptions)
				.pipe(
					map((AmenityManage) => {
					return AmenityManage;
					})
				);
			}

			addAmenityApi(role_id: AmenityManageModel): Observable<AmenityManageModel> {
				return this.http
				.post<AmenityManageModel>(this.addAmenityUrl, role_id, this.httpOptions)
				.pipe(
					map((amenityManage) => {
					return amenityManage;
					})
				);
			}

			deleteAmenityManage(amenityId: AmenityManageModel): Observable<AmenityManageModel> {
				return this.http
				.post<AmenityManageModel>(this.deleteAmenityUrl, amenityId, this.httpOptions)
				.pipe(
					map((AmenityManage) => {
					return AmenityManage;
					})
				);
			}
	
	}
