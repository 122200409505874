import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Appointment } from "../models/appointment";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class AppointmentService {
    private baseUrl = environment.baseURL + "appointment/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getAppointment(params: any): Observable<Appointment> {
        return this.http
            .post<Appointment>(`${this.baseUrl}getAppointment`, params, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }

    getAppointmentById(appointmentId: any): Observable<Appointment> {
        return this.http
            .post<Appointment>(`${this.baseUrl}getAppointmentById`, { appointmentId }, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }

    getAppointmentsByUserId(userId: any): Observable<Appointment> {
        return this.http
            .post<Appointment>(`${this.baseUrl}getAppointmentByUserId`, { userId }, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }
}
