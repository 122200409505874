import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BloodType } from "../models/bloodType";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class BloodTypeService {
    private baseUrl = environment.baseURL + "bloodType/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getBloodType(params: any): Observable<BloodType> {
        return this.http
            .post<BloodType>(`${this.baseUrl}getBloodType`, params, this.getHttpOptions())
            .pipe(map((bloodTypeList) => bloodTypeList));
    }

    getBloodTypeById(bloodTypeId: any): Observable<BloodType> {
        return this.http
            .post<BloodType>(`${this.baseUrl}getBloodTypeById`, { bloodTypeId }, this.getHttpOptions())
            .pipe(map((bloodTypeList) => bloodTypeList));
    }

    addBloodType(bloodType: BloodType): Observable<BloodType> {
        return this.http
            .post<BloodType>(`${this.baseUrl}createBloodType`, bloodType, this.getHttpOptions())
            .pipe(map((bloodType) => bloodType));
    }

    updateBloodType(bloodType: BloodType): Observable<BloodType> {
        return this.http
            .post<BloodType>(`${this.baseUrl}updateBloodType`, bloodType, this.getHttpOptions())
            .pipe(map((bloodType) => bloodType));
    }

    deleteBloodType(bloodType: BloodType): Observable<BloodType> {
        return this.http
            .post<BloodType>(`${this.baseUrl}deleteBloodType`, bloodType, this.getHttpOptions())
            .pipe(map((bloodType) => bloodType));
    }
}
