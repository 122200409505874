<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Community Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card">
					<div class="card-header border-0 pt-6">
						<div class="card-title"></div>
						<div class="card-toolbar">
						</div>
					</div>
					<div class="card-body py-4">
						<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
							<thead>
								<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
									<th>SR. No.</th>
									<th style="width: 250px;">Name</th>
									<th>Created By</th>
									<th>Status</th>
									<th>Created</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody class="text-gray-600 fw-semibold" *ngIf="communityList?.length != 0">
								<tr *ngFor="let community of communityList;let i = index">
									<td>{{i+1}}</td>
									<td class="d-flex align-items-center">
										<div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
											<a href="javascript:;">
												<div class="symbol-label">
													<img src="{{community.image ? community.image : 'assets/media/avatars/blank.png'}}" alt="{{community.name}}" class="w-100">
												</div>
											</a>
										</div>
										<div class="d-flex flex-column">
											<a href="javascript:;" class="text-gray-800 text-hover-primary mb-1" style="width: 250px;">{{community.name}}</a>
										</div>
									</td>
									<td>{{community.user_first_name}} {{community.user_last_name}}</td>
									<td *ngIf="community.status"><span class="badge badge-light-success">Active</span></td>
									<td *ngIf="!community.status"><span class="badge badge-light-danger">Inactive</span></td>
									<td>{{community.created | date :'medium'}}</td>
									<td>
										<a href="community_details/{{community.id}}"
											class="btn btn-sm btn-icon badge badge-light-primary">
											<i class="fa fa-eye text-primary"></i>
										</a>
									</td>
								</tr>
							</tbody>
							<tbody class="text-gray-600 fw-semibold" *ngIf="communityList?.length == 0">
								<tr>
									<td>No Record Found</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
