import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Amenity } from "../models/amenity";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class AmenityService {
    
    private baseURL = environment.baseURL + "amenity/";
    private endpoints = {
        getAmenity: "getAmenity",
        addAmenity: "createAmenity",
        getAmenityById: "getAmenityById",
        updateAmenity: "updateAmenity",
        deleteAmenity: "deleteAmenity",
        getAmenityByRoleId: "getAmenityByRoleId",
        updateAmenityMap: "updateAmenityMap",
        getAmenityByBusinessId: "getAmenityByBusinessId",
        getAmenityByUserId: "getAmenityByUserId"
    };

    constructor(private http: HttpClient) {}
    
    private getHttpOptions() {
        const currentUser = localStorage.getItem("currentUser");    
        let token = '';
        if (currentUser) {
            const userArray = JSON.parse(currentUser);
            token = userArray?.data?.json_token || '';
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token })
        };
    }
    
    getAmenity(params: any): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.getAmenity, params, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    addAmenity(params: Amenity): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.addAmenity, params, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    getAmenityById(amenityId: any): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.getAmenityById, { amenityId }, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    updateAmenity(params: Amenity): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.updateAmenity, params, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    deleteAmenity(params: Amenity): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.deleteAmenity, params, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    getAmenityByRoleId(role_id: any): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.getAmenityByRoleId, { role_id }, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    updateAmenityMap(params: any): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.updateAmenityMap, params, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
    
    getAmenityByBusinessId(business_id: any): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.getAmenityByBusinessId, { business_id }, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }

    getAmenityByUserId(user_id: any): Observable<Amenity> {
        return this.http.post<Amenity>(this.baseURL + this.endpoints.getAmenityByUserId, { user_id }, this.getHttpOptions())
            .pipe(map(amenityList => amenityList));
    }
}
