import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BusinessMasterModel } from "../models/businessMaster";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class BusinessMasterService {
	
		private getBusinessMasterUrl = environment.baseURL + "businessMaster/getBusinessMasterAdmin";

		// private businessMasterViewUrl = environment.baseURL + "XYZ2";

		// private businessMasterAddUrl = environment.baseURL + "XYZ3";

		// private businessMasterUpdateUrl = environment.baseURL + "XYZ4";

		// private businessMasterDeleteUrl = environment.baseURL + "XYZ5";

			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  

		getBusinessMasterApi(params: any): Observable<BusinessMasterModel> {
			return this.http
			.post<BusinessMasterModel>(this.getBusinessMasterUrl, params, this.httpOptions)
			.pipe(
				map((Businessmapreturn) => {
				return Businessmapreturn;
				})
			);
		}
	
		// openBusinessViewApi(businessMasterId: any): Observable<BusinessMasterModel> {
		// 	let businessMasterIds = { businessMasterId: businessMasterId };
		// 	return this.http
		// 	.post<BusinessMasterModel>(this.businessMasterViewUrl, businessMasterIds, this.httpOptions)
		// 	.pipe(
		// 		map((BusinessList) => {
		// 		return BusinessList;
		// 		})
		// 	);
		// }

		// addBusinessMasterApi(BusinessMasterApi: BusinessMasterModel): Observable<BusinessMasterModel> {
		// 	return this.http
		// 	.post<BusinessMasterModel>(this.businessMasterAddUrl, BusinessMasterApi, this.httpOptions)
		// 	.pipe(
		// 		map((Businessmapreturn) => {
		// 		return Businessmapreturn;
		// 		})
		// 	);
		// }

		// updateBusinessMaster(anything: BusinessMasterModel): Observable<BusinessMasterModel> {
		// 	return this.http
		// 	.post<BusinessMasterModel>(this.businessMasterUpdateUrl, anything, this.httpOptions)
		// 	.pipe(
		// 		map((everything) => {
		// 		return everything;
		// 		})
		// 	);
		// }

		// deleteApi(Delete: BusinessMasterModel): Observable<BusinessMasterModel> {
		// 	return this.http
		// 	.post<BusinessMasterModel>(this.businessMasterDeleteUrl, Delete, this.httpOptions)
		// 	.pipe(
		// 		map((DeleteApi) => {
		// 		return DeleteApi;
		// 		})
		// 	);
		// }
	}
