import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { MasterSettingsRoutingModule } from "./master-settings-routing.modules";
import { MasterSettingsComponent } from "./master-settings.component";

@NgModule({
  imports: [
    CommonModule,
    MasterSettingsRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [MasterSettingsComponent],
  providers: [AuthGuard],
})
export class MasterSettingsModule{}
