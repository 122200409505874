<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div class="w-lg-500px p-10">
                        <form class="form w-100" [formGroup]="verifyOtpForm" (ngSubmit)="verifyOtp()" id="kt_sign_in_form">
                            <div class="text-center mb-11">
                                <h2 class="text-dark fw-bolder mb-3">Enter the OTP which is already send to your phone no.</h2>
                            </div>
                            <div class="fv-row mb-8">
                                <input type="text" placeholder="OTP" formControlName="otp" autocomplete="off" class="form-control bg-transparent" [ngClass]="{'inputError': f.otp.touched && f.otp.invalid && f.otp.errors && f.otp.errors.required }" [ngClass]="{'inputError': formErrorMessages.otpError }" />
                            </div>
                            <div *ngIf="errorMessage">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div *ngIf="successMessage">
                                <div class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                            </div>
                            <div class="d-grid mb-10">
                                <button type="submit" id="kt_sign_in_submit" class="btn btn-primary">
                                    <span class="indicator-label">Verify</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>    
    <button style="display: none;" type="button" id="openModalButton" class="btn btn-primary" (click)="openConfirmDelete(confirmDeleteContent)">
        <i class="ki-duotone ki-plus fs-2"></i></button>
    <ng-template #confirmDeleteContent let-modal>
        <div class="modal-header" id="modal-basic-title-1">
            <h2 class="fw-bold">Confirm Delete</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" id="addBannerModalClose" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
                <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                </i>
            </div>
        </div>
        <div class="modal-body scroll-y mx-1 mx-xl-5 my-1">
            <form [formGroup]="confirmDeleteForm" (ngSubmit)="confirmDelete($event)" class="form">
                <div class="d-flex flex-column scroll-y me-n1 pe-4">
                    <div class="row mb-5">
                        <div class="col-md-12 fv-row">
                            <h1>Are you sure you wanted to delete the account?</h1>
                            <p>All Data will be deleted from the DonorZilla</p>
                            <p>Health details and history will be not available</p>
                        </div>
                    </div>
                    <div *ngIf="errorMessage">
                        <div class="alert alert-danger" role="alert">
                            {{errorMessage}}
                        </div>
                    </div>
                    <div *ngIf="successMessage">
                        <div class="alert alert-success" role="alert">
                            {{successMessage}}
                        </div>
                    </div>
                </div>
                <div class="text-center pt-15">
                    <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
                    <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                        <span class="indicator-label">Yes</span>
                        <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </ng-template>
