import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { VersionControl } from "../models/versionControl";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class VersionControlService {
    private versionControlBaseUrl = environment.baseURL + "versionControl/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getVersionControl(params: any): Observable<VersionControl> {
        return this.http.post<VersionControl>(`${this.versionControlBaseUrl}getVersionControl`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getVersionControlById(versionControlId: any): Observable<VersionControl> {
        return this.http.post<VersionControl>(`${this.versionControlBaseUrl}getVersionControlById`, { versionControlId }, this.getHttpOptions()).pipe(map(response => response));
    }

    addVersionControl(versionControl: VersionControl): Observable<VersionControl> {
        return this.http.post<VersionControl>(`${this.versionControlBaseUrl}createVersionControl`, versionControl, this.getHttpOptions()).pipe(map(response => response));
    }

    updateVersionControl(versionControl: VersionControl): Observable<VersionControl> {
        return this.http.post<VersionControl>(`${this.versionControlBaseUrl}updateVersionControl`, versionControl, this.getHttpOptions()).pipe(map(response => response));
    }
}
