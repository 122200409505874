import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommunityDetailsPostComponent } from './community-details-post.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'community_details_post/:id',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: CommunityDetailsPostComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityDetailsPostRoutingModule { }
