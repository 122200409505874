import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ContactUs } from "../models/contactUs";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class ContactUsService {
    private baseUrl = environment.baseURL + "contact/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getContact(params: any): Observable<ContactUs> {
        return this.http.post<ContactUs>(`${this.baseUrl}getContact`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getContactById(contactId: any): Observable<ContactUs> {
        return this.http.post<ContactUs>(`${this.baseUrl}getContactById`, { contactId }, this.getHttpOptions()).pipe(map(response => response));
    }
}
