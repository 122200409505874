import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Banner } from "../models/banner";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class BannerService {
	
	private getBannerUrl = environment.baseURL + "banner/getBanner";

	private getBannerByIdUrl = environment.baseURL + "banner/getBannerById";

	private addBannerUrl = environment.baseURL + "banner/createBanner";

	private updateBannerUrl = environment.baseURL + "banner/updateBanner";

	private deleteBannerUrl = environment.baseURL + "banner/deleteBanner";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getBanner(params: any): Observable<Banner> {
		return this.http
		.post<Banner>(this.getBannerUrl, params, this.httpOptions)
		.pipe(
			map((bannerList) => {
			return bannerList;
			})
		);
	}
	
	getBannerById(bannerId: any): Observable<Banner> {
		let bannerIds = { bannerId: bannerId };
		return this.http
		.post<Banner>(this.getBannerByIdUrl, bannerIds, this.httpOptions)
		.pipe(
			map((bannerList) => {
			return bannerList;
			})
		);
	}

	addBanner(banner: Banner): Observable<Banner> {
		return this.http
		.post<Banner>(this.addBannerUrl, banner, this.httpOptions)
		.pipe(
			map((banner) => {
			return banner;
			})
		);
	}

	updateBanner(banner: Banner): Observable<Banner> {
		return this.http
		.post<Banner>(this.updateBannerUrl, banner, this.httpOptions)
		.pipe(
			map((banner) => {
			return banner;
			})
		);
	}

	deleteBanner(banner: Banner): Observable<Banner> {
		return this.http
		.post<Banner>(this.deleteBannerUrl, banner, this.httpOptions)
		.pipe(
			map((banner) => {
			return banner;
			})
		);
	}
}
