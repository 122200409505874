import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-delete-account-thankyou',
	templateUrl: './delete-account-thankyou.component.html',
	styleUrls: [
		
	]
})
export class DeleteAccountThankyouComponent implements OnInit {

	constructor(
	) { }

	ngOnInit(): void {

	}
}