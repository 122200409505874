import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EmailTemplate } from "../models/emailTemplate";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class EmailTemplateService {
    private emailTemplateBaseUrl = environment.baseURL + "emailTemplate/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getEmailTemplate(params: any): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`${this.emailTemplateBaseUrl}getEmailTemplate`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getEmailTemplateById(emailTemplateId: any): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`${this.emailTemplateBaseUrl}getEmailTemplateById`, { emailTemplateId }, this.getHttpOptions()).pipe(map(response => response));
    }

    addEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`${this.emailTemplateBaseUrl}createEmailTemplate`, emailTemplate, this.getHttpOptions()).pipe(map(response => response));
    }

    updateEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`${this.emailTemplateBaseUrl}updateEmailTemplate`, emailTemplate, this.getHttpOptions()).pipe(map(response => response));
    }

    deleteEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`${this.emailTemplateBaseUrl}deleteEmailTemplate`, emailTemplate, this.getHttpOptions()).pipe(map(response => response));
    }
}
