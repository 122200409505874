import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmailTemplateManageComponent } from './email-template-manage.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'email_template_manage',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: EmailTemplateManageComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailTemplateManageRoutingModule { }


