import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonService } from '../services/common.service';
import { User } from '../models/user';
import { UserService } from '../services/user.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: [
		
	]
})
export class ChangePasswordComponent implements OnInit  {
	
		
	ChangeForm = new FormGroup({
		CurrentPassword: new FormControl('', [Validators.required]),
		NewPassword: new FormControl('', [Validators.required]),
		Re_enterNewPassword: new FormControl('',[Validators.required]), 
		});

		get f() {
			return this.ChangeForm.controls;
		}
		userId: any;
		users: User[] = [];
		errorMessage = '';
		successMessage = '';
		formErrorMessages = {
			CurrentPasswordError: '',
			NewPasswordError: '',
			Re_enterNewPasswordError: '',
		};
		constructor(
			private UserService: UserService,
			private router: Router,
			) { }

		ngOnInit(): void {
			const currentUser = localStorage.getItem('currentUser');
			if (currentUser) {
				var userArray = JSON.parse(currentUser);
				this.userId = userArray.data.id
			}
		}

		changePassword(): void {
			this.errorMessage = '';
			this.successMessage = '';

			let userId = this.userId;
			let CurrentPassword = this.ChangeForm.value.CurrentPassword.trim();
			let NewPassword = this.ChangeForm.value.NewPassword.trim();
			let Re_enterNewPassword = this.ChangeForm.value.Re_enterNewPassword.trim();
			if (!CurrentPassword || !NewPassword || !Re_enterNewPassword) {
				if (!CurrentPassword) {
					this.formErrorMessages.CurrentPasswordError = 'Current password is required.';
				} else {
					this.formErrorMessages.CurrentPasswordError = '';
				}
				if (!NewPassword) {
					this.formErrorMessages.NewPasswordError = 'New Password is required.';
				} else {
					this.formErrorMessages.NewPasswordError = '';
				}
				if (!Re_enterNewPassword) {
					this.formErrorMessages.Re_enterNewPasswordError = 'Conform New Password is required.';
				} else {
					this.formErrorMessages.Re_enterNewPasswordError = '';
				}
				this.errorMessage = 'All fields are required.';
				return;
			} else{
				if (NewPassword != Re_enterNewPassword) {
					this.errorMessage = 'New password not matched';
					return;
				}
			}
				this.UserService.ChangePassword({CurrentPassword, NewPassword, Re_enterNewPassword, userId} as User)
				.subscribe(
					data => {
						if (data.status == 200) {
							this.successMessage =data.message;
							
						} else {
							this.errorMessage = data.message;
							return;
						}
					},
					error => {
						this.errorMessage = error;
						return;
					}
				);
		}
		

		
}
