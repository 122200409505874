import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { MasterSettingsService } from "../services/masterSettings.service";
import { MasterSettingsModel } from "../models/masterSettings";

@Component({
  selector: 'app-master-settings',
  templateUrl: './master-settings.component.html',
  styleUrls: ['./master-settings.component.css']
})
export class MasterSettingsComponent implements OnInit {

    UpdateMasterSettingsForm = new FormGroup({
      hospital: new FormControl,
      blood_bank: new FormControl,
      path_lab: new FormControl, 
      id: new FormControl
		});

    get f() {
			return this.UpdateMasterSettingsForm.controls;
		}
		errorMessage = '';
		successMessage = '';

  constructor(
    private MasterSettingsService: MasterSettingsService,
		private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getMasterSettings();
  }

  getMasterSettings(): void {
    this.MasterSettingsService.getMasterApi().subscribe(
    (data) => {
      if (data.status == 200) {
        this.UpdateMasterSettingsForm.controls["hospital"].patchValue(data.data[0].hospital);
        this.UpdateMasterSettingsForm.controls["blood_bank"].patchValue(data.data[0].blood_bank);
        this.UpdateMasterSettingsForm.controls["path_lab"].patchValue(data.data[0].path_lab);
        this.UpdateMasterSettingsForm.controls["id"].setValue(data.data[0].id);
      }
    },
    (error) => {
    }
    );
  }

  UpdateMasterSettings(): void{
      this.errorMessage = '';
			let hospital = this.UpdateMasterSettingsForm.value.hospital;
			let blood_bank = this.UpdateMasterSettingsForm.value.blood_bank;
			let path_lab = this.UpdateMasterSettingsForm.value.path_lab;
      let id = this.UpdateMasterSettingsForm.value.id;
      hospital = hospital ? 1 : 0;
      blood_bank = blood_bank ? 1 : 0;
      path_lab = path_lab ? 1 : 0;

      this.MasterSettingsService.updateMasterApi({hospital, blood_bank, path_lab, id} as MasterSettingsModel)
      .subscribe(
        data => {
          if (data.status == 200) {
              this.successMessage = data.message;
              setTimeout(() => {

              }, 2000);
          } else {
            this.errorMessage = data.message;
          }
        },
        error => {
          this.errorMessage = error;
        }
      );
    }
 }