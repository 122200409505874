import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommunityDetailsPostService } from "../services/community-details-post.service"; 

@Component({
  selector: 'app-community-details-post',
  templateUrl: './community-details-post.component.html',
  styleUrls: ['./community-details-post.component.css']
})

export class CommunityDetailsPostComponent implements OnInit {


  	
	userId: any;
	userById: any;
	
	
	constructor(
		private CommunityDetailsPostService: CommunityDetailsPostService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getCommunityById(this.userId);
	}

	getCommunityById(userId: any): void {
		this.CommunityDetailsPostService.getCommunityPostApi(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				this.userById = data.data;
			} else {
				this.userById = [];
			}
		},
		(error) => {
		}
		);
	}
	
}

