import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { ArticleManageAddService } from "../services/articleManageAdd.service";
import { ArticleManageAddModel} from "../models/articleManageAdd";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}

@Component({
  selector: 'app-article-add',
  templateUrl: './article-add.component.html',
  styleUrls: ['./article-add.component.css']
})

export class ArticleAddComponent implements OnInit {

	dtOptions: DataTables.Settings = {};
	closeResult = "";

	userList: any;
	articleById: any;
	categoryList: any;

	selectedFile : any = ImageSnippet;
	images : string[] = [];

  	addArticleForm = new FormGroup({
		title: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		featured_image: new FormControl("", [Validators.required]),
		uploaded_by: new FormControl("", [Validators.required]),
		tags: new FormControl("", [Validators.required]),
		category_id: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		meta_title: new FormControl("", [Validators.required]),
		meta_keywords: new FormControl("", [Validators.required]),
		meta_description: new FormControl("", [Validators.required]),
		slug: new FormControl("", [Validators.required]),
    });

	errorMessage = "";
	successMessage = "";
	errorMessage1 = "";
	successMessage1 = "";
	formErrorMessages = {
		titleError : "",
		descriptionError : "",
		featured_imageError : "",
		uploaded_byError : "",
		tagsError :"",
		category_idError :"",
		statusError :"",
		meta_titleError :"",
		meta_keywordsError :"",
		meta_descriptionError :"",
	};

    get f() {
      	return this.addArticleForm.controls;
    }

	constructor(
		private articleService: ArticleManageAddService,
	) { }

	ngOnInit(): void {
		this.getArticleCategory();
		this.getarticleUploaded();
	}

	getArticleCategory(): void {
		this.articleService.getarticleCategory().subscribe(
		(data) => {
			if (data.status == 200) {
				this.categoryList = data.data;
			} else {
				this.categoryList = [];
			}
		},
		(error) => {
		}
		);
	}

	getarticleUploaded(): void {
		this.articleService.getarticleUploaded().subscribe(
		(data) => {
			if (data.status == 200) {
				this.userList = data.data;
			} else {
				this.userList = [];
			}
		},
		(error) => {
		}
		);
	}
	
	processFile(imageInput: any): void {
		const file: File = imageInput.files[0];

		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);

			this.articleService.uploadArticleImage(this.selectedFile.file).subscribe(
				data => {
					this.addArticleForm.controls["featured_image"].setValue(data.data);
				},
				error => {
					this.errorMessage = error;
				}
			)
		});
		reader.readAsDataURL(file);
	}

	onTitleChange(event: any) {
		let slug = event.target.value.replace(/\s+/g, '-').toLowerCase();
		this.addArticleForm.controls["slug"].setValue(slug);
   	};

  	addArticleManage($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		
		let title = this.addArticleForm.value.title.trim();
		let description = $('#description_content').val();
    	let featured_image = this.addArticleForm.value.featured_image;
    	let uploaded_by = this.addArticleForm.value.uploaded_by.trim();
    	let tags = $('#tags-add').val();
    	let category_id = this.addArticleForm.value.category_id.trim();
    	let status = this.addArticleForm.value.status.trim();
    	let meta_title = this.addArticleForm.value.meta_title.trim();
    	let meta_keywords = this.addArticleForm.value.meta_keywords.trim();
    	let meta_description = this.addArticleForm.value.meta_description.trim();
    	let slug = this.addArticleForm.value.slug.trim();
		
    	if (
			!title ||
			!description ||
			!featured_image ||
			!uploaded_by ||
			!tags ||
			!category_id ||
			!status ||
			!meta_title ||
			!meta_keywords ||
			!slug ||
			!meta_description
      	) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
	  	}
		this.articleService
		.addArticleManageApi({
			title,
			description,
			featured_image,
      		uploaded_by,
      		tags,
    		category_id,
    		status,
      		meta_title,
      		meta_keywords,
			slug,
      		meta_description
		} as ArticleManageAddModel)
		.subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessage = data.message;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
}