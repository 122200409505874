<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Business Master Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10">
					<div class="card-body pt-9 pb-0">
						<div class="d-flex flex-wrap flex-sm-nowrap">
							<!--begin: Pic-->
							<div class="me-7 mb-4">
								<div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
									<img alt="Pic" [src]="userById.profile_picture" id="imgPlacement" [hidden]="!userById.profile_picture" />
								</div>
							</div>
							<div class="flex-grow-1">
								<div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
									<!--begin::User-->
									<div class="d-flex flex-column">
										<!--begin::Name-->
										<div class="d-flex align-items-center mb-2">
											<div class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{userById.name}}</div>
											
										</div>
										<!--end::Name-->
				
										<!--begin::Info-->                        
										<div class="fw-semibold fs-6 mb-4 pe-2">
											<div class="d-flex align-items-center text-gray-500 text-hover-primary mb-2">
												<i class="fa fa-envelope fs-4 me-1"><span class="path1"></span><span class="path2"></span></i>{{userById.email}}
											</div>
											<div class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
												<i class="fa fa-phone fs-4 me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>{{userById.c_phone}}
											</div>
											<div class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
												<i class="fa fa-map-marker-alt fs-4 me-1"><span class="path1"></span><span class="path2"></span></i> {{userById.address}}
											</div>
											
										</div>
										<div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
											<a href="{{userById.facebook_link}}" target="_blank" class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
											 <i class="bi bi-facebook me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a>
											<a href="{{userById.twitter_link}}" target="_blank" class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
											 <i class="bi bi-twitter me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a>
											<a href="{{userById.instagram_link}}" target="_blank" class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
											 <i class="bi bi-instagram me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a>
											<a href="{{userById.linkedin_link}}" target="_blank" class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
											 <i class="bi bi-linkedin me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a>
											 <a href="{{userById.youtube_link}}" target="_blank" class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
											 <i class="bi bi-youtube me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a>
											 <a href="{{userById.website_link}}" target="_blank" class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
											 <i class="bi bi-globe me-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a>
										</div>
										
									</div>
									
								</div>
							</div>
						</div>
						<ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BusinessDetailsById(userId)">Overview</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BusinessAmenityById(userId)">Amenity</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BusinessDepartmentById(userId)">Department</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BusinessDoctorById(userId)">Doctor</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BusinessImageById(userId)">Images</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BusinessSlotDetailsById(userId)">Slot</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
					<div class="card" id="kt_profile_details_view">
						<div class="card-body p-9" *ngIf="overviewData">
							<div class="row mb-7">
								<div class="main-sas">
									<div class="row">
										<div class="col-lg-6">
											<h2 class="mb-5">Business Information</h2>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Emergency Number</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.e_phone}}</span>
												</div>
											</div>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Latitude - Longitude</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.latitude}} - {{userById.longitude}}</span>
												</div>
											</div>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Public</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.make_public  == '1' ? 'Yes' : 'No'}}</span>
												</div>
											</div>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Newsletter</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.newsletter == '1' ? 'Yes' : 'No'}}</span>
												</div>
											</div>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Latest Update</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.latest_update  == '1' ? 'Yes' : 'No'}}</span>
												</div>
											</div>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">About</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.about}}</span>
												</div>
											</div>
										</div>
										<div class="col-lg-6">
											<h2 class="mb-5">Contact Person Details</h2>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Full Name</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.c_name}}</span>
												</div>
											</div>
											
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Email</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.c_email}}</span>
												</div>
											</div>
											<div class="row mb-7">
												<label class="col-lg-4 fw-semibold text-muted">Phone</label>
												<div class="col-lg-8">
													<span class="fw-bold fs-6 text-gray-800">{{userById.c_phone}}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<a href="business_master" class="btn btn-primary">Back</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="amenity">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="aminty-es">
									<div class="row" *ngIf="businessAmenityList?.length != 0">
										<div class="col-sm-3" *ngFor="let user of businessAmenityList;let i = index">
											<div class="amitns">
												<img src="{{user.image}}" height="60" />
											    <p class="mt-5">{{user.name}}</p>
										    </div>
										</div>
									</div>
									<div class="row" *ngIf="businessAmenityList?.length == 0">
										<div class="col-sm-3">
											<p>No Record Found</p>
										</div>
									</div>
									
									<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<a href="business_master" class="btn btn-primary">Back</a>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="department">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="department-1-list">
									<div class="row" *ngIf="businessDepartmentList?.length != 0">
										<div class="col-sm-3" *ngFor="let user of businessDepartmentList;let i = index">
											<div class="amitns">
												<img src="{{user.image}}" height="60" />
											    <p class="mt-5"><b>{{user.name}}</b></p>
												<p class="mt-5">{{user.description}}</p>
										    </div>
										</div>
									</div>
									<div class="row" *ngIf="businessDepartmentList?.length == 0">
										<div class="col-sm-3">
											<div class="amitns">
												<p class="mt-5">No Record Found</p>
										    </div>
										</div>
									</div>
									
									<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<a href="business_master" class="btn btn-primary">Back</a>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="doctor">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="Doctors-list-1">
									<div class="row" *ngIf="businessDoctorList?.length != 0">
										<div class="col-sm-3" *ngFor="let user of businessDoctorList;let i = index">
											<div class="amitns doc-lis-1">
												<img src="{{user.image}}" height="60" />
											    <h3 class="mt-5">{{user.name}}</h3>
												<p class="mt-5"><b>{{user.department_name}}</b></p>
												<p class="mt-5">{{user.bio}}</p>
										    </div>
										</div>
									</div>
									<div class="row" *ngIf="businessDoctorList?.length == 0">
										<div class="col-sm-3">
											<div class="amitns">
												<p class="mt-5">No Record Found</p>
										    </div>
										</div>
									</div>
									
									<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<a href="business_master" class="btn btn-primary">Back</a>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="image">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="images-up-1">
									<div class="row"  *ngIf="businessImagesList?.length != 0">
										<div class="col-sm-2 mb-5" *ngFor="let user of businessImagesList;let i = index">
											<img src="{{user.image}}" height="60" class="sims-1" />
										</div>
									</div>
									<div class="row"  *ngIf="businessImagesList?.length == 0">
										<div class="col-sm-2">
											<p>No Record Found</p>
										</div>
									</div>
									<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<a href="business_master" class="btn btn-primary">Back</a>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="slotDetails">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="slotde-ails">
									<table class="table align-middle table-row-dashed fs-6 gy-5">
										<thead>
											<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
												<th class="w-100px pe-2">SR. No.</th>
												<th class="min-w-125px">ID</th>
												<th class="min-w-125px">Business Id</th>
												<th class="min-w-125px">Future Days</th>
												<th class="min-w-125px">Slot Duration</th>
												<th class="min-w-125px">Date & Time</th>
												<th class="min-w-125px">Created</th>

											</tr>
										</thead>
										<tbody class="text-gray-600 fw-semibold" *ngIf="businessSlotList?.length != 0">
											<tr *ngFor="let user of businessSlotList;let i = index">
												<td>{{i+1}}</td>
												<td>{{user.id}}</td>
												<td>{{user.business_id}}</td>
												<td>{{user.future_days}}</td>
												<td>{{user.slot_duration}}</td>
												<td>{{user.date_time_json}}</td>
												<td>{{user.created}}</td>

										
										</tr>
										</tbody>
										<tbody class="text-gray-600 fw-semibold" *ngIf="businessSlotList?.length == 0">
											<tr>
												<td>No Record Found</td>
											</tr>
										</tbody>
									</table>
									<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<a href="business_master" class="btn btn-primary">Back</a>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</div>	
