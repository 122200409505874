import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { AmenityManageService } from "../services/amenityManage.service";
import { AmenityManageModel} from "../models/amenityManage";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}

@Component({
  selector: 'app-amenity-manage',
  templateUrl: './amenity-manage.component.html',
  styleUrls: ['./amenity-manage.component.css']
})
export class AmenityManageComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  closeResult = "";

  amenityList: any;
  roleList: any;
  amenityById: any;

  updateAmenityForm = new FormGroup({
		role_id: new FormControl("", [Validators.required]),
		fileSource: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		id: new FormControl("", [Validators.required]),
		
  	});

  addAmenityForm = new FormGroup({
      role_id: new FormControl("", [Validators.required]),
      fileSource: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      status: new FormControl("", [Validators.required]),
      
      });

      deleteAmenityForm = new FormGroup({
        AmenityId: new FormControl("", [Validators.required]),
      });

    errorMessage = "";
    successMessage = "";
    errorMessage1 = "";
    successMessage1 = "";
    formErrorMessages = {
      role_idError : "",
      imageError : "",
      nameError : "",
      statusError : "",
    };

    get f() {
      return this.addAmenityForm.controls;
    }

    selectedFile : any = ImageSnippet;

	  images : string[] = [];


  constructor(
    private amenityService: AmenityManageService,
    private amenityModalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getAmenity();
  }

  getAmenity(): void {
		this.amenityList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.amenityService.getAmenityApi(dataTablesParameters).subscribe(
				(data) => {
					that.amenityList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}
  processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.images.push(event.target.result); 

				this.addAmenityForm.patchValue({
					fileSource: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

  processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.images.push(event.target.result); 

				this.updateAmenityForm.patchValue({
					fileSource: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

  openAmenityEdit(content: any, Id: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.amenityService.getRoleId().subscribe(
			(data) => {
				if (data.status == 200) {
					this.roleList = data.data;
				} else {
					this.roleList = [];
				}
			},
			(error) => {
			}
			);
		this.amenityService.getAmenityById(Id).subscribe(
		(data) => {
			if (data.status == 200) {
			this.amenityById = data.data;
			this.updateAmenityForm.controls["role_id"].setValue(data.data.role_id);
			this.updateAmenityForm.controls["fileSource"].setValue(data.data.image);
			this.updateAmenityForm.controls["name"].setValue(data.data.name);
			this.updateAmenityForm.controls["status"].setValue(data.data.status);
			this.updateAmenityForm.controls["id"].setValue(data.data.id);
			
			} else {
			this.amenityById = [];
			}
		},
		(error) => {
		}
		);
		this.amenityModalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

  updateAmenityManage($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let role_id = this.updateAmenityForm.value.role_id.trim();
		let image = this.updateAmenityForm.value.fileSource;
		let name = this.updateAmenityForm.value.name.trim();
		let status = this.updateAmenityForm.value.status;
		let amenityId = this.updateAmenityForm.value.id;
		
		if (
		!role_id ||
		!image ||
		!name ||
		!status ||
		!amenityId
		) {
		if (!role_id) {
			this.formErrorMessages.role_idError = "Amenity Manage Role Id is required.";
		} else {
			this.formErrorMessages.role_idError = "";
		}
		if (!image) {
			this.formErrorMessages.imageError = "Amenity Manage Image is required.";
		} else {
			this.formErrorMessages.imageError = "";
		}
		if (!name) {
			this.formErrorMessages.nameError = "Amenity Manage Name is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Amenity Manage Status is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}
		
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.amenityService
		.updateAmenity({
			role_id,
			image,
			name,
			status,
			amenityId
		} as AmenityManageModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateAmenityManageModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
  
  openAmenityAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.amenityService.getRoleId().subscribe(
			(data) => {
				if (data.status == 200) {
					this.roleList = data.data;
				} else {
					this.roleList = [];
				}
			},
			(error) => {
			}
			);
    	this.amenityModalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

  addAmenity($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let role_id = this.addAmenityForm.value.role_id.trim();
		let name = this.addAmenityForm.value.name.trim();
		let image = this.addAmenityForm.value.fileSource;
		let status = this.addAmenityForm.value.status.trim();
		if (
		!role_id ||
		!name ||
		!image ||
		!status
		) {
		if (!role_id) {
			this.formErrorMessages.role_idError = "Amenity Manage Role Id field is required.";
		} else {
			this.formErrorMessages.role_idError = "";
		}
		if (!name) {
			this.formErrorMessages.nameError = "Amenity Manage Name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!image) {
			this.formErrorMessages.imageError = "Amenity Manage Image field is required.";
		} else {
			this.formErrorMessages.imageError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Amenity Manage Status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}
	

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.amenityService
		.addAmenityApi({
			role_id,
			name,
			image,
			status
		} as AmenityManageModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addAmenityManageModalClose")?.click();
				this.addAmenityForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

  deleteAmenityManage(id:string): void {

		let amenityId = id
		if (!amenityId) {
		this.errorMessage1 = "Something wrong happen.";
		return;
		}
		this.amenityService.deleteAmenityManage({ amenityId } as AmenityManageModel).subscribe(
		(data) => {
			this.errorMessage1 = "";
			if (data.status == 200) {
			this.successMessage1 = data.message;
			setTimeout(() => {
				document.getElementById("deleteAmenitymanageModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage1 = data.message;
			}
		},
		(error) => {
			this.errorMessage1 = error;
		}
		);
	 }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}


}
