<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div class="w-lg-500px p-10">
                        <form class="form w-100" [formGroup]="deleteAccountForm" (ngSubmit)="deleteAccount()" id="kt_sign_in_form">
                            <div class="fv-row mb-8">
                                <input type="text" placeholder="Phone no" formControlName="phone" autocomplete="off" class="form-control bg-transparent" [ngClass]="{'inputError': f.phone.touched && f.phone.invalid && f.phone.errors && f.phone.errors.required }" [ngClass]="{'inputError': formErrorMessages.phoneError }" />
                            </div>
                            <div *ngIf="errorMessage">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div *ngIf="successMessage">
                                <div class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                            </div>
                            <div class="d-grid mb-10">
                                <button type="submit" id="kt_sign_in_submit" class="btn btn-primary">
                                    <span class="indicator-label">Request</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>    
