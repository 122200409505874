import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { CommunityDetailsPostModel } from "../models/communityDetailsPost";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class CommunityDetailsPostService {
	
	private getCommunityByIdUrl = environment.baseURL + "community/getCommunityPostById";

	
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}

	  getCommunityPostApi(community_id: any): Observable<CommunityDetailsPostModel> {
		let communityIds = { community_id: community_id };
		return this.http
		.post<CommunityDetailsPostModel>(this.getCommunityByIdUrl, communityIds, this.httpOptions)
		.pipe(
			map((CommunityList) => {
			return CommunityList;
			})
		);
	}

}