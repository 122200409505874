import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TermsConditionRoutingModule } from './terms-condition-routing.module';
import { TermsConditionComponent } from './terms-condition.component';

@NgModule({
  imports: [
    CommonModule,
    TermsConditionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [TermsConditionComponent]
})
export class TermsConditionModule { }
