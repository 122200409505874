import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { BannerService } from "../services/banner.service";
import { Banner } from "../models/banner";

@Component({
	selector: "app-banner-list",
	templateUrl: "./banner-list.component.html",
	styleUrls: ["./banner-list.component.css"],
})
export class BannerListComponent implements OnInit {
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addBannerForm = new FormGroup({
		url: new FormControl("", [Validators.required]),
  	});

  	updateBannerForm = new FormGroup({
		url: new FormControl("", [Validators.required]),
		bannerId: new FormControl("", [Validators.required]),
  	});

	deleteBannerForm = new FormGroup({
		bannerId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addBannerForm.controls;
	}

	bannerList: any;
	bannerById: any;
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		imageError : "",
		urlError : ""
	};

	constructor(
		private bannerService: BannerService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getBanner();
	}

  	getBanner(): void {
		this.bannerList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.bannerService.getBanner(dataTablesParameters).subscribe(
				(data) => {
					that.bannerList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "url" }, { data: "created" }],
		};
	}

	openBannerAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBannerEdit(content: any, bannerId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.bannerService.getBannerById(bannerId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.bannerById = data.data;
			this.updateBannerForm.controls["name"].setValue(data.data.url);
			this.updateBannerForm.controls["bannerId"].setValue(data.data.id);
			} else {
			this.bannerById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBannerView(content: any, bannerId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.bannerService.getBannerById(bannerId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.bannerById = data.data;
			} else {
			this.bannerById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBannerDelete(content: any, bannerId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteBannerForm.controls["bannerId"].setValue(bannerId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addBanner($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let url = this.addBannerForm.value.url.trim();
		if (
		!url
		) {
		if (!url) {
			this.formErrorMessages.urlError = "Banner url field is required.";
		} else {
			this.formErrorMessages.urlError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.bannerService
		.addBanner({
			url
		} as Banner)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addBannerModalClose")?.click();
				this.addBannerForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateBanner($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let url = this.updateBannerForm.value.url.trim();
		let bannerId = this.updateBannerForm.value.bannerId;
		if (
		!url ||
		!bannerId
		) {
		if (!url) {
			this.formErrorMessages.urlError = "Banner url field is required.";
		} else {
			this.formErrorMessages.urlError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.bannerService
		.updateBanner({
			url,
			bannerId
		} as Banner)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateBannerModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteBanner(): void {

		let bannerId = this.deleteBannerForm.value.bannerId;
		if (!bannerId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.bannerService.deleteBanner({ bannerId } as Banner).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deleteBannerModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}
