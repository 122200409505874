import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BusinessMasterService } from "../services/businessMaster.service";
import { BusinessMasterModel } from "../models/businessMaster";


@Component({
  selector: 'app-business-master',
  templateUrl: './business-master.component.html',
  styleUrls: ['./business-master.component.css']
})

export class BusinessMasterComponent implements OnInit {


  dtOptions: DataTables.Settings = {};
  closeResult = "";
  	
    addBusinessMasterForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      role_name: new FormControl("", [Validators.required]),
      created: new FormControl("", [Validators.required]),
      });

    updateBusinessMasterForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      role_name: new FormControl("", [Validators.required]),
      created: new FormControl("", [Validators.required]),
      });


		   businessMaster: any;
		   businessMasterContent: any;
       businessMasterEdit: any;
		

      get f() {
        return this.addBusinessMasterForm.controls;
      }

        businessMasterList: any;
    	  businessMasterById: any;
        
			  errorMessage = "";
			  successMessage = "";
          formErrorMessages = {
          nameError : "",
          phoneError : "",
          role_nameError : "",
          createdError : "",
          };

        constructor(
            private BusinessMasterService: BusinessMasterService,
            private modalService: NgbModal
        ) {}
      

        ngOnInit(): void {
          this.getBusinessMaster();
        }

        getBusinessMaster(): void {
          this.businessMasterList = [];
          const that = this;

          this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
              this.BusinessMasterService.getBusinessMasterApi(dataTablesParameters).subscribe(
              (data) => {
                that.businessMasterList = data.data;
                callback({
                  recordsTotal: data.recordsTotal,
                  recordsFiltered: data.recordsFiltered,
                  data: [],
                });
              },
              (error) => {
              }
              );
            },
            columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
          };
        }

        openBusinessMasterAdd(id: any): void {
            this.successMessage = "";
            this.errorMessage = "";

            this.modalService
            .open(id, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
            .result.then(
              (result) => {
              this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
          }

      private getDismissReason(reason: any): string {
		    if (reason === ModalDismissReasons.ESC) {
		    return "by pressing ESC";
		    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		    return "by clicking on a backdrop";
		    } else {
		    return `with: ${reason}`;
		  }
	  }
  }
