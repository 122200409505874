import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { BadgeService } from "../services/badge.service";
import { Badge } from "../models/badge";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}

@Component({
	selector: "app-badge-list",
	templateUrl: "./badge-list.component.html",
	styleUrls: ["./badge-list.component.css"],
})
export class BadgeListComponent implements OnInit {
  	
	dtOptions: DataTables.Settings = {};
	
	closeResult = "";
  	
	addBadgeForm = new FormGroup({
		title: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		fileSource: new FormControl("", [Validators.required]),
		no_of_user: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
  	});

  	updateBadgeForm = new FormGroup({
		title: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		fileSource: new FormControl("", [Validators.required]),
		no_of_user: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		badgeId: new FormControl("", [Validators.required]),
  	});

	deleteBadgeForm = new FormGroup({
		badgeId: new FormControl("", [Validators.required]),
	});

	get f() {
		return this.addBadgeForm.controls;
	}

	badgeList: any;
	badgeById: any;

	selectedFile : any = ImageSnippet;

	images : string[] = [];
	
	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		titleError : "",
		descriptionError : "",
		imageError : "",
		no_of_userError : "",
		statusError : "",
	};

	constructor(
		private badgeService: BadgeService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.getBadge();
	}

	getBadge(): void {
		this.badgeList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.badgeService.getBadge(dataTablesParameters).subscribe(
				(data) => {
					that.badgeList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.images.push(event.target.result); 

				this.addBadgeForm.patchValue({
					fileSource: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.images.push(event.target.result); 

				this.updateBadgeForm.patchValue({
					fileSource: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	openBadgeAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBadgeEdit(content: any, badgeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.badgeService.getBadgeById(badgeId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.badgeById = data.data;
			this.updateBadgeForm.controls["title"].setValue(data.data.title);
			this.updateBadgeForm.controls["description"].setValue(data.data.description);
			this.updateBadgeForm.controls["fileSource"].setValue(data.data.image);
			this.updateBadgeForm.controls["no_of_user"].setValue(data.data.no_of_user);
			this.updateBadgeForm.controls["status"].setValue(data.data.status);
			this.updateBadgeForm.controls["badgeId"].setValue(data.data.id);
			} else {
			this.badgeById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBadgeView(content: any, badgeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.badgeService.getBadgeById(badgeId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.badgeById = data.data;
			} else {
			this.badgeById = [];
			}
		},
		(error) => {
		}
		);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBadgeDelete(content: any, badgeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteBadgeForm.controls["badgeId"].setValue(badgeId);
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addBadge($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let title = this.addBadgeForm.value.title.trim();
		let description = this.addBadgeForm.value.description.trim();
		let image = this.addBadgeForm.value.fileSource;
		let no_of_user = this.addBadgeForm.value.no_of_user.trim();
		let status = this.addBadgeForm.value.status.trim();
		if (
		!title ||
		!description ||
		!image ||
		!no_of_user ||
		!status
		) {
		if (!title) {
			this.formErrorMessages.titleError = "Blood Bank title field is required.";
		} else {
			this.formErrorMessages.titleError = "";
		}
		if (!description) {
			this.formErrorMessages.descriptionError = "Blood Bank description field is required.";
		} else {
			this.formErrorMessages.descriptionError = "";
		}
		if (!image) {
			this.formErrorMessages.imageError = "Blood Bank image field is required.";
		} else {
			this.formErrorMessages.imageError = "";
		}
		if (!no_of_user) {
			this.formErrorMessages.no_of_userError = "Blood Bank no_of_user field is required.";
		} else {
			this.formErrorMessages.no_of_userError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.badgeService
		.addBadge({
			title,
			description,
			image,
			no_of_user,
			status
		} as Badge)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
					window.location.reload();
				}, 2000);
				
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateBadge($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let title = this.updateBadgeForm.value.title.trim();
		let description = this.updateBadgeForm.value.description.trim();
		let image = this.updateBadgeForm.value.fileSource;
		let no_of_user = this.updateBadgeForm.value.no_of_user.trim();
		let status = this.updateBadgeForm.value.status.trim();
		let badgeId = this.updateBadgeForm.value.badgeId;
		
		if (
		!title ||
		!description ||
		!image ||
		!no_of_user ||
		!status ||
		!badgeId
		) {
		if (!title) {
			this.formErrorMessages.titleError = "Badge title field is required.";
		} else {
			this.formErrorMessages.titleError = "";
		}
		if (!description) {
			this.formErrorMessages.descriptionError = "Blood Bank description field is required.";
		} else {
			this.formErrorMessages.descriptionError = "";
		}
		if (!image) {
			this.formErrorMessages.imageError = "Blood Bank image field is required.";
		} else {
			this.formErrorMessages.imageError = "";
		}
		if (!no_of_user) {
			this.formErrorMessages.no_of_userError = "Blood Bank no_of_user field is required.";
		} else {
			this.formErrorMessages.no_of_userError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Blood Bank status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.badgeService
		.updateBadge({
			title,
			description,
			image,
			no_of_user,
			status,
			badgeId
		} as Badge)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteBadge(): void {

		let badgeId = this.deleteBadgeForm.value.badgeId;
		if (!badgeId) {
		this.errorMessage = "Something wrong happen.";
		return;
		}
		this.badgeService.deleteBadge({ badgeId } as Badge).subscribe(
		(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
			this.successMessage = data.message;
			setTimeout(() => {
				document.getElementById("deleteBadgeModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage = data.message;
			}
		},
		(error) => {
			this.errorMessage = error;
		}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}
