import { Component, OnInit } from '@angular/core';

import { CommonService } from '../services/common.service';
import { User } from '../models/user';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [
		'./profile.component.css'
	]
})
export class ProfileComponent implements OnInit {
	
	userCounter: any;
	bloodCounter: any;
	appointmentCounter: any;

	userList: any;
	bloodList: any;
	profileContent: any;
	profileContentById: any;

	users: User[] = [];
		errorMessage = '';
		successMessage = '';


	constructor(
		private CommonService: CommonService,
	) {}

	ngOnInit() {
		this.ProfileView();
		}

	
	ProfileView(): void {
			this.successMessage = "";
			this.errorMessage = "";
	
	this.CommonService.getProfile()
				.subscribe(
					(data) => {
						if (data.status == 200) {
						this.profileContentById = data.data;
						} else {
						this.profileContentById = [];
						}
					},
					(error) => {
					}
					);
	}
}


