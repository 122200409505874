import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class UserService {
    private userBaseUrl = environment.baseURL + "user/";
    private notificationBaseUrl = environment.baseURL + "notification/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getAllUser(params: any, role_type: any): Observable<User> {
        params.role_type = role_type;
        return this.http.post<User>(`${this.userBaseUrl}getAllUser`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getUserById(userId: any): Observable<User> {
        return this.http.post<User>(`${this.userBaseUrl}getUserById`, { userId }, this.getHttpOptions()).pipe(map(response => response));
    }

    getFavouriteById(userId: any): Observable<User> {
        return this.http.post<User>(`${this.userBaseUrl}getFavouriteByUserId`, { user_id: userId }, this.getHttpOptions()).pipe(map(response => response));
    }

    getLoginById(userId: any): Observable<User> {
        return this.http.post<User>(`${this.userBaseUrl}getLoginLogByUserId`, { user_id: userId }, this.getHttpOptions()).pipe(map(response => response));
    }

    getNotificationById(userId: any): Observable<User> {
        return this.http.post<User>(`${this.notificationBaseUrl}getNotificationByUserId`, { user_id: userId }, this.getHttpOptions()).pipe(map(response => response));
    }

    getReferralById(userId: any): Observable<User> {
        return this.http.post<User>(`${this.userBaseUrl}getReferralHistoryByUserId`, { user_id: userId }, this.getHttpOptions()).pipe(map(response => response));
    }

    getAllAdmin(): Observable<User> {
        return this.http.get<User>(`${this.userBaseUrl}getAllAdmin`, this.getHttpOptions()).pipe(map(response => response));
    }

    getAllUserRole(): Observable<User> {
        return this.http.get<User>(`${this.userBaseUrl}getAllUserRole`, this.getHttpOptions()).pipe(map(response => response));
    }
}
