import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DeleteAccountComponent } from './delete-account.component';

const routes: Routes = [
  {
    path: 'delete_account',
    component: DeleteAccountComponent,
    data: { showHeader: false, showSidebar: false, showFooter: false }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeleteAccountRoutingModule { }
