import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinessMasterDetailsService } from "../services/businessMasterDetails.service";
import { BusinessMasterDetailsModel } from "../models/businessMasterDetails";

@Component({
  selector: 'app-business-master-details',
  templateUrl: './business-master-details.component.html',
  styleUrls: ['./business-master-details.component.css']
})

export class BusinessMasterDetailsComponent implements OnInit {

  userId: any;
	userById: any;

  constructor(
    private BusinessMasterDetailsService: BusinessMasterDetailsService,
		private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
		this.BusinessDetailsById(this.userId);
  }

  BusinessDetailsById(userId: any): void {
		this.BusinessMasterDetailsService.getBusinessDetails(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				this.userById = data.data;
			} else {
				this.userById = [];
			}
		},
		(error) => {
		}
		);
	}
}