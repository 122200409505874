import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DepartmentManageModel } from "../models/departmentManage";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class DepartmentManageService {
	
		private getDepartmentUrl = environment.baseURL + "department/getDepartment";
		private getDepartmentByIdUrl = environment.baseURL + "department/getDepartmentById";
		private updateDepartmentUrl = environment.baseURL + "department/updateDepartment";
		private addDepartmentUrl = environment.baseURL + "department/createDepartment";
		private deleteDepartmentUrl = environment.baseURL + "department/deleteDepartment";


			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  
			  getDepartmentApi(params: any): Observable<DepartmentManageModel> {
				return this.http
				.post<DepartmentManageModel>(this.getDepartmentUrl, params, this.httpOptions)
				.pipe(
					map((DepartmentList) => {
					return DepartmentList;
					})
				);
			}

			getDepartmentById(departmentId: any): Observable<DepartmentManageModel> {
				let departmentIds = { departmentId: departmentId };
				return this.http
				.post<DepartmentManageModel>(this.getDepartmentByIdUrl, departmentIds, this.httpOptions)
				.pipe(
					map((DepartmentList) => {
					return DepartmentList;
					})
				);
			}

			updateDepartment(departmentId: DepartmentManageModel): Observable<DepartmentManageModel> {
				return this.http
				.post<DepartmentManageModel>(this.updateDepartmentUrl, departmentId, this.httpOptions)
				.pipe(
					map((DepartmentManage) => {
					return DepartmentManage;
					})
				);
			}

			addDepartmentApi(role_id: DepartmentManageModel): Observable<DepartmentManageModel> {
				return this.http
				.post<DepartmentManageModel>(this.addDepartmentUrl, role_id, this.httpOptions)
				.pipe(
					map((departmentManage) => {
					return departmentManage;
					})
				);
			}

			deleteDepartmentManage(departmentId: DepartmentManageModel): Observable<DepartmentManageModel> {
				return this.http
				.post<DepartmentManageModel>(this.deleteDepartmentUrl, departmentId, this.httpOptions)
				.pipe(
					map((Manage) => {
					return Manage;
					})
				);
			}
	
	}
