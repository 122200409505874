import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommunityModel } from "../models/community";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class CommunityService {
    private baseUrl = environment.baseURL + "community/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getCommunity(params: any): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}getCommunity`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getCommunityById(communityId: any): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}getCommunityById`, { communityId }, this.getHttpOptions()).pipe(map(response => response));
    }

    addCommunity(community: CommunityModel): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}createCommunity`, community, this.getHttpOptions()).pipe(map(response => response));
    }

    updateCommunity(community: CommunityModel): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}updateCommunity`, community, this.getHttpOptions()).pipe(map(response => response));
    }

    deleteCommunity(community: CommunityModel): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}deleteCommunity`, community, this.getHttpOptions()).pipe(map(response => response));
    }

    getCommunityPostApi(community_id: any): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}getCommunityPostById`, { community_id }, this.getHttpOptions()).pipe(map(response => response));
    }

    getCommunityUserApi(community_id: any): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}getCommunityUsersById`, { community_id }, this.getHttpOptions()).pipe(map(response => response));
    }

    getCommunityLogsApi(community_id: any): Observable<CommunityModel> {
        return this.http.post<CommunityModel>(`${this.baseUrl}getCommunityLogsById`, { community_id }, this.getHttpOptions()).pipe(map(response => response));
    }
}
