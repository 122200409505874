import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BusinessMaster } from "../models/businessMaster";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class BusinessMasterService {
    private baseUrl = environment.baseURL + "businessMaster/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getBusinessMasterAdmin(params: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${this.baseUrl}getBusinessMasterAdmin`, params, this.getHttpOptions())
            .pipe(map((businessMapReturn) => businessMapReturn));
    }

    getBusinessDetails(b_id: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${this.baseUrl}getInfoByBusinessId`, { b_id }, this.getHttpOptions())
            .pipe(map((businessDetailsList) => businessDetailsList));
    }

    getAmenityDetails(b_id: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${environment.baseURL}amenity/getAmenityByBusinessId`, { b_id }, this.getHttpOptions())
            .pipe(map((businessDetailsList) => businessDetailsList));
    }

    getDepartmentDetails(b_id: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${environment.baseURL}department/getDepartmentByBusinessId`, { b_id }, this.getHttpOptions())
            .pipe(map((businessDetailsList) => businessDetailsList));
    }

    getDoctorDetails(b_id: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${environment.baseURL}doctor/getDoctorByBusinessId`, { b_id }, this.getHttpOptions())
            .pipe(map((businessDetailsList) => businessDetailsList));
    }

    getImageDetails(b_id: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${this.baseUrl}getGalleryImagesById`, { b_id }, this.getHttpOptions())
            .pipe(map((businessList) => businessList));
    }

    getBusinessSlotDetails(b_id: any): Observable<BusinessMaster> {
        return this.http
            .post<BusinessMaster>(`${environment.baseURL}slotMaster/getSlotByBusinessId`, { b_id }, this.getHttpOptions())
            .pipe(map((businessDetailsList) => businessDetailsList));
    }

    getBusinessId(): Observable<BusinessMaster> {
        return this.http
            .get<BusinessMaster>(`${this.baseUrl}getAllBusinessMasterAdmin`, this.getHttpOptions())
            .pipe(map((get) => get));
    }
}
