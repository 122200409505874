import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class DashboardService {
    private baseUrl = environment.baseURL + "dashboard/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getUserCounterDashboard(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}getUserCounterDashboard`, this.getHttpOptions()).pipe(map(response => response));
    }

    getBloodCounterDashboard(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}getBloodCounterDashboard`, this.getHttpOptions()).pipe(map(response => response));
    }

    getAppointmentCounterDashboard(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}getAppointmentCounterDashboard`, this.getHttpOptions()).pipe(map(response => response));
    }

    getLatestBloodListDashboard(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}getLatestBloodListDashboard`, this.getHttpOptions()).pipe(map(response => response));
    }

    getLatestUserListDashboard(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}getLatestUserListDashboard`, this.getHttpOptions()).pipe(map(response => response));
    }
}
