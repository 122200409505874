import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { ArticleCategoryService } from "../services/articleCategory.service";
import { ArticleCategoryModel} from "../models/articleCategory";

@Component({
  selector: 'app-article-category',
  templateUrl: './article-category.component.html',
  styleUrls: ['./article-category.component.css']
})

export class ArticleCategoryComponent implements OnInit {


  dtOptions: DataTables.Settings = {};
  closeResult = "";

  articleList: any;
  articleById: any;

  updateArticleForm = new FormGroup({
	description: new FormControl("", [Validators.required]),
	name: new FormControl("", [Validators.required]),
	status: new FormControl("", [Validators.required]),
	UpdateId: new FormControl("",[Validators.required]),
		
  	});

  addArticleForm = new FormGroup({
	description: new FormControl("", [Validators.required]),
	name: new FormControl("", [Validators.required]),
	status: new FormControl("", [Validators.required]),
      
      });

      deleteArticleForm = new FormGroup({
        ArticleId: new FormControl("", [Validators.required]),
      });

    errorMessage = "";
    successMessage = "";
    errorMessage1 = "";
    successMessage1 = "";
    formErrorMessages = {
		descriptionError : "",
		nameError : "",
		statusError : "",
    };

    get f() {
      return this.addArticleForm.controls;
    }

  constructor(
    private articleService: ArticleCategoryService,
    private articleModalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getArticle();
  }

  getArticle(): void {
		this.articleList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.articleService.getArticleApi(dataTablesParameters).subscribe(
				(data) => {
					that.articleList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}
 
  openArticleEdit(content: any, ArticleId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.articleService.getArticleById(ArticleId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.articleById = data.data;
			this.updateArticleForm.controls["name"].setValue(data.data.name);
			this.updateArticleForm.controls["status"].setValue(data.data.status);
			this.updateArticleForm.controls["UpdateId"].setValue(data.data.id);
			
			} else {
			this.articleById = [];
			}
		},
		(error) => {
		}
		);
		this.articleModalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

  updateArticleTypes($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		
		let articleCategoryId = this.updateArticleForm.value.UpdateId;
		let name = this.updateArticleForm.value.name.trim();
		let status = this.updateArticleForm.value.status.trim();
		
		if (
		!name ||
		!status ||
		!articleCategoryId
		) {
		
		if (!name) {
			this.formErrorMessages.nameError = "Article Category Name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Article Category Status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}
		
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.articleService
		.updateArticle({
      name,
	  status,
      articleCategoryId
		} as ArticleCategoryModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateArticleCategoryModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
  
	openArticleAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";
    
		this.articleModalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

  addArticle($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.addArticleForm.value.name.trim();
	  	let status = this.addArticleForm.value.status.trim();
		if (
		
		!name ||
		!status
		) {
		
		if (!name) {
			this.formErrorMessages.nameError = "Article Category Name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Article Category Status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}
	
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.articleService
		.addArticleApi({
			name,
			status
		} as ArticleCategoryModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addAmenityManageModalClose")?.click();
				this.addArticleForm.reset();
				}, 2000);
				// setTimeout(() => {
				// window.location.reload();
				// }, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

  deleteArticleTypes(id:string): void {

		let articleCategoryId = id
		if (!articleCategoryId) {
		this.errorMessage1 = "Something wrong happen.";
		return;
		}
		this.articleService.deleteArticleTypes({ articleCategoryId } as ArticleCategoryModel).subscribe(
		(data) => {
			this.errorMessage1 = "";
			if (data.status == 200) {
			this.successMessage1 = data.message;
			setTimeout(() => {
				document.getElementById("deleteArticleCategoryModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage1 = data.message;
			}
		},
		(error) => {
			this.errorMessage1 = error;
		}
		);
	 }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}


}
