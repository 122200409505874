import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BusinessMasterDetailsModel } from "../models/businessMasterDetails";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class BusinessMasterDetailsService {
	
		private getBusinessMasterDetailsUrl = environment.baseURL + "businessMaster/getInfoByBusinessId";


			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  
			  getBusinessDetails(b_id: any): Observable<BusinessMasterDetailsModel> {
				let businessIds = { b_id: b_id };
				return this.http
				.post<BusinessMasterDetailsModel>(this.getBusinessMasterDetailsUrl, businessIds, this.httpOptions)
				.pipe(
					map((BusinessDetailsList) => {
					return BusinessDetailsList;
					})
				);
			}
	
	}
