import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Newsletter } from "../models/newsletter";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class NewsletterService {
    private newsletterBaseUrl = environment.baseURL + "newsletter/";

    constructor(private http: HttpClient) { }

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    getNewsletter(params: any): Observable<Newsletter> {
        return this.http.post<Newsletter>(`${this.newsletterBaseUrl}getNewsletter`, params, this.getHttpOptions()).pipe(map(response => response));
    }
}
