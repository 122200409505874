import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ArticleManageAddModel } from "../models/articleManageAdd";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class ArticleManageAddService {
	
		private addArticleManageUrl = environment.baseURL + "article/createArticle";
		private addArticleCategoryUrl = environment.baseURL + "articleCategory/getAllArticleCategory";
		private addArticleUploadedUrl = environment.baseURL + "user/getAllAdmin";
		private uploadArticleImageUrl = environment.baseURL + "article/uploadArticleImage";



			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  
			getarticleCategory(): Observable<ArticleManageAddModel> {
				return this.http
				.get<ArticleManageAddModel>(this.addArticleCategoryUrl, this.httpOptions)
				.pipe(
					map((Get) => {
						return Get;
					})
				);
			}

			getarticleUploaded(): Observable<ArticleManageAddModel> {
				return this.http
				.get<ArticleManageAddModel>(this.addArticleUploadedUrl, this.httpOptions)
				.pipe(
					map((Get) => {
						return Get;
					})
				);
			}
			

			addArticleManageApi(title: ArticleManageAddModel): Observable<ArticleManageAddModel> {
				return this.http
				.post<ArticleManageAddModel>(this.addArticleManageUrl, title, this.httpOptions)
				.pipe(
					map((ArticleManage) => {
					return ArticleManage;
					})
				);
			}

			
			uploadArticleImage(image: File): Observable<any> {
				const formData = new FormData();
				formData.append('featured_image', image);
				return this.http.post(this.uploadArticleImageUrl, formData);
			}


	}
