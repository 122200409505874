import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { HospitalTypesRoutingModule } from "./hospital-types-routing.modules";
import { HospitalTypesComponent } from "./hospital-types.component";

@NgModule({
  imports: [
    CommonModule,
    HospitalTypesRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [HospitalTypesComponent],
  providers: [AuthGuard],
})
export class HospitalTypesModule{}
