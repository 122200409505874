import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ArticleManageEditModel } from "../models/articleManageEdit";

import { environment } from '../../environments/environment';

	@Injectable({
		providedIn: "root",
	})
	export class ArticleManageEditService {
	
		private getArticleByIdUrl = environment.baseURL + "article/getArticleById";
		private updateArticleManageUrl = environment.baseURL + "article/updateArticle";
		
			
		httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			};

  			constructor(private http: HttpClient) {}
	  
			

			getArticleManageById(articleId: any): Observable<ArticleManageEditModel> {
				let articleIds = { articleId: articleId };
				return this.http
				.post<ArticleManageEditModel>(this.getArticleByIdUrl, articleIds, this.httpOptions)
				.pipe(
					map((Article) => {
					return Article;
					})
				);
			}

			updateArticleManage(articleId: ArticleManageEditModel): Observable<ArticleManageEditModel> {
				return this.http
				.post<ArticleManageEditModel>(this.updateArticleManageUrl, articleId, this.httpOptions)
				.pipe(
					map((ArticleManage) => {
					return ArticleManage;
					})
				);
			}
	
	}
