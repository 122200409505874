import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Badge } from "../models/badge";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class BadgeService {
    private baseUrl = environment.baseURL + "badge/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getBadge(params: any): Observable<Badge> {
        return this.http
            .post<Badge>(`${this.baseUrl}getBadge`, params, this.getHttpOptions())
            .pipe(map((badgeList) => badgeList));
    }

    getBadgeById(badgeId: any): Observable<Badge> {
        return this.http
            .post<Badge>(`${this.baseUrl}getBadgeById`, { badgeId }, this.getHttpOptions())
            .pipe(map((badgeList) => badgeList));
    }

    addBadge(params: Badge): Observable<Badge> {
        return this.http
            .post<Badge>(`${this.baseUrl}createBadge`, params, this.getHttpOptions())
            .pipe(map((badgeList) => badgeList));
    }

    updateBadge(params: Badge): Observable<Badge> {
        return this.http
            .post<Badge>(`${this.baseUrl}updateBadge`, params, this.getHttpOptions())
            .pipe(map((badgeList) => badgeList));
    }

    deleteBadge(params: Badge): Observable<Badge> {
        return this.http
            .post<Badge>(`${this.baseUrl}deleteBadge`, params, this.getHttpOptions())
            .pipe(map((badgeList) => badgeList));
    }

    getBadgeHistoryById(userId: any): Observable<Badge> {
        return this.http
            .post<Badge>(`${this.baseUrl}getBadgeHistoryByUserId`, { userId }, this.getHttpOptions())
            .pipe(map((badgeList) => badgeList));
    }
}
