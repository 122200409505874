import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommunityDetailsUserService } from "../services/community-details-user.service";

@Component({
  selector: 'app-community-details-user',
  templateUrl: './community-details-user.component.html',
  styleUrls: ['./community-details-user.component.css']
})


export class CommunityDetailsUserComponent implements OnInit {
  	
	userId: any;
	userById: any;
	

	constructor(
		private CommunityDetailsUserService: CommunityDetailsUserService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getCommunityUserById(this.userId);
	}
	
	getCommunityUserById(userId: any): void {
		this.CommunityDetailsUserService.getCommunityUserApi(userId).subscribe(
		(data) => {
			if (data.status == 200) {
				this.userById = data.data;
				
			} else {
				this.userById = [];
			}
		},
		(error) => {
		}
		);
	}
	
	

}