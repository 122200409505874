import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';

import { ArticleManageAddService } from "../services/articleManageAdd.service";

import { ArticleManageEditService } from "../services/articleManageEdit.service";
import { ArticleManageEditModel} from "../models/articleManageEdit";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}
@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.css']
})

export class ArticleEditComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  closeResult = "";
  userId: any;
	userById: any;

	userList: any;
  articleById: any;
  categoryList: any;

  updateArticleForm = new FormGroup({
	  title: new FormControl("", [Validators.required]),
      edit_description_content: new FormControl("", [Validators.required]),
      slug: new FormControl("", [Validators.required]),
      edit_description: new FormControl("", [Validators.required]),
      featured_image: new FormControl("", [Validators.required]),
      uploaded_by: new FormControl("", [Validators.required]),
      tags: new FormControl("", [Validators.required]),
      category_id: new FormControl("", [Validators.required]),
      status: new FormControl("", [Validators.required]),
      meta_title: new FormControl("", [Validators.required]),
      meta_keywords: new FormControl("", [Validators.required]),
      meta_description: new FormControl("", [Validators.required]),
      articleId: new FormControl("", [Validators.required]),
		
  	});
    errorMessage = "";
    successMessage = "";
    errorMessage1 = "";
    successMessage1 = "";
    formErrorMessages = {
      titleError : "",
      descriptionError : "",
      featured_imageError : "",
      uploaded_byError : "",
      tagsError :"",
      category_idError :"",
      statusError :"",
      meta_titleError :"",
      meta_keywordsError :"",
      meta_descriptionError :"",

    };

    get f() {
      return this.updateArticleForm.controls;
    }

	selectedFile : any = ImageSnippet;

	  images : string[] = [];


  constructor(
    private articleService: ArticleManageEditService,
	private article1Service: ArticleManageAddService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
   this.userId = this.route.snapshot.paramMap.get('id');
		this.openArticleManageEdit(this.userId);

		this.getArticleCategory();
		this.getarticleUploaded();
  }
  getArticleCategory(): void {
	this.article1Service.getarticleCategory().subscribe(
	(data) => {
		if (data.status == 200) {
			this.categoryList = data.data;
		} else {
			this.categoryList = [];
		}
	},
	(error) => {
	}
	);
}

getarticleUploaded(): void {
	this.article1Service.getarticleUploaded().subscribe(
	(data) => {
		if (data.status == 200) {
			this.userList = data.data;
		} else {
			this.userList = [];
		}
	},
	(error) => {
	}
	);
}
	
  openArticleManageEdit(articleId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.articleService.getArticleManageById(articleId).subscribe(
		(data: any) => {
			if (data.status == 200) {
			this.articleById = data.data;
			this.updateArticleForm.controls["title"].setValue(data.data.title);
			this.updateArticleForm.controls["edit_description_content"].setValue(data.data.description);
			this.updateArticleForm.controls["featured_image"].setValue(data.data.featured_image);
			this.updateArticleForm.controls["slug"].setValue(data.data.slug);
			this.updateArticleForm.controls["uploaded_by"].setValue(data.data.uploaded_by);
      		this.updateArticleForm.controls["tags"].patchValue(data.data.tags);
      		this.updateArticleForm.controls["category_id"].setValue(data.data.category_id);
      		this.updateArticleForm.controls["status"].setValue(data.data.status);
      		this.updateArticleForm.controls["meta_title"].setValue(data.data.meta_title);
      		this.updateArticleForm.controls["meta_keywords"].setValue(data.data.meta_keywords);
      		this.updateArticleForm.controls["meta_description"].setValue(data.data.meta_description);
      		this.updateArticleForm.controls["articleId"].setValue(data.data.id);
			} else {
			this.articleById = [];
			}
		},
		(error) => {
		}
		);
		
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files[0];

		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);

			this.article1Service.uploadArticleImage(this.selectedFile.file).subscribe(
				data => {
					this.updateArticleForm.controls["featured_image"].setValue(data.data);
				},
				error => {
					this.errorMessage = error;
				}
			)
		});
		reader.readAsDataURL(file);
	}

	onTitleChange(event: any) {
		let slug = event.target.value.replace(/\s+/g, '-').toLowerCase();
		this.updateArticleForm.controls["slug"].setValue(slug);
   	};

  updateArticleManage($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let description = $('#edit_description_content').val();
	let title = this.updateArticleForm.value.title.trim();
    let slug = this.updateArticleForm.value.slug.trim();
    let featured_image = this.updateArticleForm.value.featured_image;
    let uploaded_by = this.updateArticleForm.value.uploaded_by;
	let tags = $('#tags-add').val();

    let category_id = this.updateArticleForm.value.category_id;
    let status = this.updateArticleForm.value.status.trim();
    let meta_title = this.updateArticleForm.value.meta_title.trim();
    let meta_keywords = this.updateArticleForm.value.meta_keywords.trim();
    let meta_description = this.updateArticleForm.value.meta_description.trim();
		
    let articleId = this.updateArticleForm.value.articleId;
		
		if (
   		!title ||
    	!description ||
    	!featured_image ||
    	!uploaded_by ||
    	//!tags ||
    	!slug ||
    	!category_id ||
    	!status ||
    	!meta_title ||
    	!meta_keywords ||
		!meta_description ||
		!articleId
		) {
		
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.articleService
		.updateArticleManage({
			title,
			description,
			featured_image,
      		uploaded_by,
      		tags,
      		category_id,
      		status,
      		meta_title,
			slug,
      		meta_keywords,
      		meta_description,
			  articleId
		} as ArticleManageEditModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
  }
