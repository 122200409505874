import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { ArticleManageService } from "../services/articleManage.service";
import { ArticleManageModel} from "../models/articleManage";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}

@Component({
    selector: 'app-article-manage',
    templateUrl: './article-manage.component.html',
     styleUrls: ['./article-manage.component.css']
  })

export class ArticleManageComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  closeResult = "";

  articleList: any;
  articleById: any;

  updateArticleForm = new FormGroup({
	  title: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      fileSource: new FormControl("", [Validators.required]),
      uploaded_by: new FormControl("", [Validators.required]),
      tags: new FormControl("", [Validators.required]),
      category_id: new FormControl("", [Validators.required]),
      status: new FormControl("", [Validators.required]),
      meta_title: new FormControl("", [Validators.required]),
      meta_keywords: new FormControl("", [Validators.required]),
      meta_description: new FormControl("", [Validators.required]),
		
  	});



	  deleteAmenityForm = new FormGroup({
        ArticleId: new FormControl("", [Validators.required]),
      });

    errorMessage = "";
    successMessage = "";
    errorMessage1 = "";
    successMessage1 = "";
    formErrorMessages = {
      titleError : "",
      descriptionError : "",
      featured_imageError : "",
      uploaded_byError : "",
      tagsError :"",
      category_idError :"",
      statusError :"",
      meta_titleError :"",
      meta_keywordsError :"",
      meta_descriptionError :"",

    };

    get f() {
      return this.updateArticleForm.controls;
    }

	selectedFile : any = ImageSnippet;

	  images : string[] = [];


  constructor(
    private articleService: ArticleManageService,
  ) { }

  ngOnInit(): void {
    this.getArticleManage();
  }

  getArticleManage(): void {
		this.articleList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.articleService.getArticleManageApi(dataTablesParameters).subscribe(
				(data) => {
					that.articleList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}
	
  deleteArticleManage(id:string): void {

		let articleId = id
		if (!articleId) {
		this.errorMessage1 = "Something wrong happen.";
		return;
		}
		this.articleService.deleteArticleManageApi({ articleId } as ArticleManageModel).subscribe(
		(data) => {
			this.errorMessage1 = "";
			if (data.status == 200) {
			this.successMessage1 = data.message;
			setTimeout(() => {
				document.getElementById("deleteArticleManageModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage1 = data.message;
			}
		},
		(error) => {
			this.errorMessage1 = error;
		}
		);
	 }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}


}
