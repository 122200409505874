import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { ArticleAddRoutingModule } from "./article-add-routing.module";
import { ArticleAddComponent } from "./article-add.component";

@NgModule({
  imports: [
    CommonModule,
    ArticleAddRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ArticleAddComponent],
  providers: [AuthGuard],
})
export class ArticleAddModule{}
