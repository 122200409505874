<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Community Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10">
					<div class="card-body pt-9 pb-0">
						<div class="d-flex flex-wrap flex-sm-nowrap">
							<div class="me-7 mb-4">
								<div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
									<img src="{{communityDetails.image}}" alt="image">
								</div>
							</div>
							<div class="flex-grow-1">
								<div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
									<div class="d-flex flex-column">
										<div class="d-flex align-items-center mb-2">
											<div class="text-gray-900 me-1">
												<h2>{{communityDetails.name}}</h2>
												<p class="mb-0"><b>Created By:</b> {{communityDetails.user_first_name}} {{communityDetails.user_last_name}}</p>
												<p><b>Created Date:</b> {{communityDetails.date_filter}}</p>
												<p>{{communityDetails.description}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-5 mb-xl-10">
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="py-4">
									<h2 class="mb-5">Users</h2>
									<div class="row scroll-side-1" *ngIf="communityUserList.length != 0">
										<div class="col-3" *ngFor="let user of communityUserList;">
											<div class="card card-body use-rlisti">
												<div class="use-rlistimg pb-10 text-align-center"><img src="{{user.profile_picture}}" width="100" height="100" /></div>
												<h3>{{user.first_name}} {{user.last_name}}</h3>
											</div>
										</div>
									</div>
									<div class="row scroll-side-1" *ngIf="communityUserList.length == 0">
										<div class="col-3">
											<p>No Record Found</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-5 mb-xl-10">
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="py-4">
									<h2>Post</h2>
									<div class="s-chatting">	
									<div class="card mt-7" id="kt_chat_messenger">
										<div class="card-body" id="kt_chat_messenger_body">
											<div style="max-height: 550px;" data-kt-scroll-offset="5px" class="scroll-y me-n5 pe-5 h-300px h-lg-auto">
										<div class="start-chatting" *ngIf="communityUserPost.length != 0">
										<div class="mb-5" *ngFor="let user of communityUserPost;">
											<div class="d-flex flex-column align-items-start">
												<div class="d-flex align-items-center mb-2">
													<div class="symbol  img-100 symbol-circle "><img alt="Pic" src="{{user.profile_picture}}" ></div>
														<div class="ms-3">
															<a class="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{{user.first_name}} {{user.last_name}}</a>
														</div>
												</div>
												<div class="p-5 rounded text-gray-900 mw-lg-100 fw-semibold text-start" data-kt-element="message-text">
													<p>{{user.message}}</p>
													 <img class="mw-lg-100px" alt="Pic" [src]="user.image" id="imgPlacement" [hidden]="!user.image">
													</div>
													
											</div>
										</div>
									</div>
									</div>	
										</div>
									</div>
								</div>
									<div class="s-chatting" *ngIf="communityUserPost.length == 0"><p>No Record Found</p></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
				<a href="community_list" class="btn btn-primary">Back</a>
			</div>
			</div>
		</div>
	</div>
</div>
