import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: [
		
	]
})
export class LogoutComponent implements OnInit {

	constructor(
		private router: Router,
	) { }

	ngOnInit(): void {
		this.logout();
	}

	logout(): void {
		localStorage.removeItem("currentUser");
		this.router.navigate(['login']);
	}
}
