import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ArticleCategory } from "../models/articleCategory";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class ArticleCategoryService {
    private baseUrl = environment.baseURL + "articleCategory/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getArticleCategory(params: any): Observable<ArticleCategory> {
        return this.http
            .post<ArticleCategory>(`${this.baseUrl}getArticleCategory`, params, this.getHttpOptions())
            .pipe(map((articleCategoryList) => articleCategoryList));
    }

    addArticleCategory(params: ArticleCategory): Observable<ArticleCategory> {
        return this.http
            .post<ArticleCategory>(`${this.baseUrl}createArticleCategory`, params, this.getHttpOptions())
            .pipe(map((articleCategoryList) => articleCategoryList));
    }

    getArticleCategoryById(articleCategoryId: any): Observable<ArticleCategory> {
        return this.http
            .post<ArticleCategory>(`${this.baseUrl}getArticleCategoryById`, { articleCategoryId }, this.getHttpOptions())
            .pipe(map((articleCategoryList) => articleCategoryList));
    }

    updateArticleCategory(params: ArticleCategory): Observable<ArticleCategory> {
        return this.http
            .post<ArticleCategory>(`${this.baseUrl}updateArticleCategory`, params, this.getHttpOptions())
            .pipe(map((articleCategoryList) => articleCategoryList));
    }

    deleteArticleCategory(params: ArticleCategory): Observable<ArticleCategory> {
        return this.http
            .post<ArticleCategory>(`${this.baseUrl}deleteArticleCategory`, params, this.getHttpOptions())
            .pipe(map((articleCategoryList) => articleCategoryList));
    }

    getAllArticleCategory(): Observable<ArticleCategory> {
        return this.http
            .get<ArticleCategory>(`${this.baseUrl}getAllArticleCategory`, this.getHttpOptions())
            .pipe(map((articleCategoryList) => articleCategoryList));
    }
}
