import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TermsConditionComponent } from './terms-condition.component';

const routes: Routes = [
  {
    path: 'terms_condition',
    component: TermsConditionComponent,
    data: { showHeader: false, showSidebar: false, showFooter: false }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TermsConditionRoutingModule { }
