<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Business Master Listing</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div *ngIf="errorMessage">
					<div class="alert alert-danger" role="alert">
						{{errorMessage}}
					</div>
				</div>
				<div *ngIf="successMessage">
					<div class="alert alert-success" role="alert">
						{{successMessage}}
					</div>
				</div>
				<div class="card">
					<div class="card-header border-0 pt-6">
						<div class="card-title"></div>
						<div class="card-toolbar">
							<ng-template #BusinessMasterAdd let-modal>
								</ng-template>
							</div>
						</div>
						<div class="card-body py-4">
							<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions">
								<thead>
									<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
										<th>SR. No.</th>
										<th>Name</th>
										<th>Contact Number</th>
										<th>Role Type</th>
										<th>Created</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody class="text-gray-600 fw-semibold" *ngIf="businessMasterList?.length != 0">
									<tr *ngFor="let business of businessMasterList;let i = index">
										<td>{{i+1}}</td>
										<td>{{business.name}}</td>
										<td>{{business.phone}}</td>
										<td>{{business.role_type}}</td>
										<td>{{business.created }}</td>
										<td>
											
											<a href="business_master_details/{{business.id}}" class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
												<i class="ki-duotone ki-arrow-right fs-2">
													<span class="path1"></span>
													<span class="path2"></span>
												</i>
											</a>
										</td>
									</tr>
								</tbody>
								<tbody class="text-gray-600 fw-semibold" *ngIf="businessMasterList?.length == 0">
									<tr>
										<td>No Record Found</td>
									</tr>
								</tbody>
							</table>
						</div>
				</div>
			</div>
			<ng-template #businessMasterContent let-modal>
				<div class="modal-header" id="modal-basic-title">
					<h2 class="fw-bold">View Business Master</h2>
					<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
						<i class="ki-duotone ki-cross fs-1">
							<span class="path1"></span>
							<span class="path2"></span>
						</i>
					</div>
				</div>
				<div class="modal-body scroll-y">
					<form class="form">
						<div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
							<div class="table-responsive">
								<table class="table table-striped" style="line-height: 50px;;">
									<tr>
										<th class="text-right">Full Name</th>
										<td>{{businessMasterById.name}}</td>
									</tr>
									<tr>
										<th class="text-right">Contact Number</th>
										<td>{{businessMasterById.phone}}</td>
									</tr>
									<tr>
										<th class="text-right">Role Type</th>
										<td>{{businessMasterById.role_name}}</td>
									</tr>
									<tr>
									<tr>
										<th class="text-right">Created</th>
										<td>{{businessMasterById.created | date : "medium"}}</td>
									</tr>
								</table>
								<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3" data-kt-users-modal-action="cancel">Cancel</button>
							</div>
						</div>
					</form>
				</div>
			</ng-template>
		
