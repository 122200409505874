import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { DepartmentManageService } from "../services/departmentManage.service";
import { DepartmentManageModel} from "../models/departmentManage";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}
@Component({
  selector: 'app-department-manage',
  templateUrl: './department-manage.component.html',
  styleUrls: ['./department-manage.component.css']
})
export class DepartmentManageComponent implements OnInit {


  dtOptions: DataTables.Settings = {};
  closeResult = "";

  departmentList: any;
  departmentById: any;

  updateDepartmentForm = new FormGroup({
		type: new FormControl("", [Validators.required]),
		fileSource: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		id: new FormControl("", [Validators.required]),
		
  	});

  addDepartmentForm = new FormGroup({
      type: new FormControl("", [Validators.required]),
      fileSource: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      status: new FormControl("", [Validators.required]),
      
      });

      deleteAmenityForm = new FormGroup({
        AmenityId: new FormControl("", [Validators.required]),
      });

    errorMessage = "";
    successMessage = "";
    errorMessage1 = "";
    successMessage1 = "";
    formErrorMessages = {
      typeError : "",
      imageError : "",
      nameError : "",
      statusError : "",
    };

    get f() {
      return this.addDepartmentForm.controls;
    }

    selectedFile : any = ImageSnippet;

	  images : string[] = [];


  constructor(
    private departmentService: DepartmentManageService,
    private departmentModalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getDepartment();
  }

  getDepartment(): void {
		this.departmentList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.departmentService.getDepartmentApi(dataTablesParameters).subscribe(
				(data) => {
					that.departmentList = data.data;
					callback({
						recordsTotal: data.recordsTotal,
						recordsFiltered: data.recordsFiltered,
						data: [],
					});
				},
				(error) => {
				}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}
  processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.images.push(event.target.result); 

				this.addDepartmentForm.patchValue({
					fileSource: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

  processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event:any) => {
				this.images.push(event.target.result); 

				this.updateDepartmentForm.patchValue({
					fileSource: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

  openDepartmentEdit(content: any, DepartmentId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.departmentService.getDepartmentById(DepartmentId).subscribe(
		(data) => {
			if (data.status == 200) {
			this.departmentById = data.data;
			this.updateDepartmentForm.controls["type"].setValue(data.data.type);
			this.updateDepartmentForm.controls["fileSource"].setValue(data.data.image);
			this.updateDepartmentForm.controls["name"].setValue(data.data.name);
			this.updateDepartmentForm.controls["status"].setValue(data.data.status);
			this.updateDepartmentForm.controls["id"].setValue(data.data.id);
			} else {
			this.departmentById = [];
			}
		},
		(error) => {
		}
		);
		this.departmentModalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

  updateDepartmentManage($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let type = this.updateDepartmentForm.value.type.trim();
		let image = this.updateDepartmentForm.value.fileSource;
		let name = this.updateDepartmentForm.value.name.trim();
		let status = this.updateDepartmentForm.value.status.trim();
		let departmentId = this.updateDepartmentForm.value.id;
		
		if (
		!type ||
		!image ||
		!name ||
		!status ||
		!departmentId
		) {
		if (!type) {
			this.formErrorMessages.typeError = "Department Manage Type is required.";
		} else {
			this.formErrorMessages.typeError = "";
		}
		if (!image) {
			this.formErrorMessages.imageError = "Department Manage Image is required.";
		} else {
			this.formErrorMessages.imageError = "";
		}
		if (!name) {
			this.formErrorMessages.nameError = "Department Manage Name is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Department Manage Status is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}
		
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.departmentService
		.updateDepartment({
      type,
			name,
			image,
      status,
			departmentId
		} as DepartmentManageModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("updateAmenityManageModalClose")?.click();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
  
  openDepartmentAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";
    
		this.departmentModalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

  addDepartment($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let type = this.addDepartmentForm.value.type.trim();
		let name = this.addDepartmentForm.value.name.trim();
		let image = this.addDepartmentForm.value.fileSource;
		let status = this.addDepartmentForm.value.status.trim();
		if (
		!type ||
		!name ||
		!image ||
		!status
		) {
		if (!type) {
			this.formErrorMessages.typeError = "Department Manage Role Id field is required.";
		} else {
			this.formErrorMessages.typeError = "";
		}
		if (!name) {
			this.formErrorMessages.nameError = "Department Manage Name field is required.";
		} else {
			this.formErrorMessages.nameError = "";
		}
		if (!image) {
			this.formErrorMessages.imageError = "Department Manage Image field is required.";
		} else {
			this.formErrorMessages.imageError = "";
		}
		if (!status) {
			this.formErrorMessages.statusError = "Department Manage Status field is required.";
		} else {
			this.formErrorMessages.statusError = "";
		}
	

		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;

		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.departmentService
		.addDepartmentApi({
			type,
			name,
			image,
			status
		} as DepartmentManageModel)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				document.getElementById("addAmenityManageModalClose")?.click();
				this.addDepartmentForm.reset();
				}, 2000);
				setTimeout(() => {
				window.location.reload();
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

  deleteDepartmentManage(id:string): void {

		let departmentId = id
		if (!departmentId) {
		this.errorMessage1 = "Something wrong happen.";
		return;
		}
		this.departmentService.deleteDepartmentManage({ departmentId } as DepartmentManageModel).subscribe(
		(data) => {
			this.errorMessage1 = "";
			if (data.status == 200) {
			this.successMessage1 = data.message;
			setTimeout(() => {
				document.getElementById("deleteAmenitymanageModalClose")?.click();
			}, 2000);
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			} else {
			this.errorMessage1 = data.message;
			}
		},
		(error) => {
			this.errorMessage1 = error;
		}
		);
	 }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}


}
