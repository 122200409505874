import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Doctor } from "../models/doctor";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class DoctorService {
    private doctorBaseUrl = environment.baseURL + "doctor/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", "token": token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    getDoctor(params: any): Observable<Doctor> {
        return this.http.post<Doctor>(`${this.doctorBaseUrl}getDoctorAdmin`, params, this.getHttpOptions()).pipe(map(response => response));
    }

    getDoctorById(doctorId: any): Observable<Doctor> {
        return this.http.post<Doctor>(`${this.doctorBaseUrl}getDoctorById`, { doctorId }, this.getHttpOptions()).pipe(map(response => response));
    }

    addDoctor(doctor: Doctor): Observable<Doctor> {
        return this.http.post<Doctor>(`${this.doctorBaseUrl}createDoctor`, doctor, this.getHttpOptions()).pipe(map(response => response));
    }

    updateDoctor(doctor: Doctor): Observable<Doctor> {
        return this.http.post<Doctor>(`${this.doctorBaseUrl}updateDoctor`, doctor, this.getHttpOptions()).pipe(map(response => response));
    }

    deleteDoctor(doctor: Doctor): Observable<Doctor> {
        return this.http.post<Doctor>(`${this.doctorBaseUrl}deleteDoctor`, doctor, this.getHttpOptions()).pipe(map(response => response));
    }
}
