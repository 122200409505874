import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BloodRequest } from "../models/bloodRequest";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class BloodRequestService {
    private baseUrl = environment.baseURL + "bloodRequest/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getBloodRequest(params: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(`${this.baseUrl}getBloodRequest`, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getBloodRequestByUserId(userId: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(`${this.baseUrl}getBloodRequestByUserId`, { userId }, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getBloodRequestById(bloodRequestId: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(`${this.baseUrl}getBloodRequestById`, { bloodRequestId }, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getDonorList(request_id: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(`${this.baseUrl}getBloodRequestDonorsById`, { request_id }, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getDonorRatingReview(request_id: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(`${this.baseUrl}getBloodRequestReviewsById`, { request_id }, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getDonorChatApi(request_id: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(`${this.baseUrl}getBloodRequestChatById`, { request_id }, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }
}
