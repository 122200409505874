import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: "app-blood-request-details",
	templateUrl: "./blood-request-details.component.html",
	styleUrls: ["./blood-request-details.component.css"],
})
export class BloodRequestDetailsComponent implements OnInit {

	userId: any;
	bloodRequestDetails: any;
	donorList: any;
	reviewList: any;
	chatList: any;

	constructor(
		private bloodRequestService: BloodRequestService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.BloodRequestById(this.userId);
		this.donorListView(this.userId);
		this.donorRatingReview(this.userId);
		this.donorChat(this.userId);
	}
	
	BloodRequestById(userId: any): void {
		this.bloodRequestService.getBloodRequestById(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.bloodRequestDetails = data.data;
				} else {
					this.bloodRequestDetails = [];
				}
			},
			(error) => {
			}
		);
	}

	donorListView(userId: any): void {
		this.bloodRequestService.getDonorList(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.donorList = data.data;
				} else {
					this.donorList = [];
				}
			},
			(error) => {
			}
		);
	}

	donorRatingReview(userId: any): void {
		this.bloodRequestService.getDonorRatingReview(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.reviewList = data.data;
					
				} else {
					this.reviewList = [];
				}
			},
			(error) => {
			}
		);
	}

	donorChat(userId: any): void {
		this.bloodRequestService.getDonorChatApi(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.chatList = data.data;
					
				} else {
					this.chatList = [];
				}
			},
			(error) => {
			}
		);
	}
}
