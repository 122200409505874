import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Banner } from "../models/banner";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class BannerService {
    private baseUrl = environment.baseURL + "banner/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) { }

    getBanner(params: any): Observable<Banner> {
        return this.http
            .post<Banner>(`${this.baseUrl}getBanner`, params, this.getHttpOptions())
            .pipe(map((bannerList) => bannerList));
    }

    getBannerById(bannerId: any): Observable<Banner> {
        return this.http
            .post<Banner>(`${this.baseUrl}getBannerById`, { bannerId }, this.getHttpOptions())
            .pipe(map((bannerList) => bannerList));
    }

    addBanner(params: Banner): Observable<Banner> {
        return this.http
            .post<Banner>(`${this.baseUrl}createBanner`, params, this.getHttpOptions())
            .pipe(map((banner) => banner));
    }

    updateBanner(params: Banner): Observable<Banner> {
        return this.http
            .post<Banner>(`${this.baseUrl}updateBanner`, params, this.getHttpOptions())
            .pipe(map((banner) => banner));
    }

    deleteBanner(params: Banner): Observable<Banner> {
        return this.http
            .post<Banner>(`${this.baseUrl}deleteBanner`, params, this.getHttpOptions())
            .pipe(map((banner) => banner));
    }
}
