import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { HospitalTypeService } from "../services/hospitalType.service";
import { HospitalType } from "../models/hospitalType";

@Component({
	selector: 'app-hospital-types',
	templateUrl: './hospital-types.component.html',
	styleUrls: ['./hospital-types.component.css']
})
export class HospitalTypesComponent implements OnInit {


	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";
	hospitalTypeList: any;
	deleteHospitalTypeId: any;
	
	addHospitalTypeForm = new FormGroup({
		description: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateHospitalTypeForm = new FormGroup({
		description: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		hospitalTypeId: new FormControl("", [Validators.required]),
	});

	errorMessage = "";
	successMessage = "";

	get f() {
		return this.addHospitalTypeForm.controls;
	}

	constructor(
		private hospitalTypeService: HospitalTypeService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getHospitalType();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getHospitalType(): void {
		this.hospitalTypeList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.hospitalTypeService.getHospitalType(dataTablesParameters).subscribe(
					(data) => {
						that.hospitalTypeList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openHospitalTypeAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	addHospitalType($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let description = this.addHospitalTypeForm.value.description.trim();
		let name = this.addHospitalTypeForm.value.name.trim();
		let status = this.addHospitalTypeForm.value.status.trim();
		if (
			!description ||
			!name ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.hospitalTypeService
			.addHospitalType({
				description,
				name,
				status
			} as HospitalType)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.addHospitalTypeForm.reset(
							{
								"name": "",
								"description": "",
								"status": ""
							}
						);
						document.getElementById("addHospitalTypeModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openHospitalTypeEdit(content: any, hospitalTypeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.hospitalTypeService.getHospitalTypeById(hospitalTypeId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateHospitalTypeForm.controls["description"].setValue(data.data.description);
					this.updateHospitalTypeForm.controls["name"].setValue(data.data.name);
					this.updateHospitalTypeForm.controls["status"].setValue(data.data.status);
					this.updateHospitalTypeForm.controls["hospitalTypeId"].setValue(data.data.id);
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	updateHospitalType($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let description = this.updateHospitalTypeForm.value.description.trim();
		let name = this.updateHospitalTypeForm.value.name.trim();
		let status = this.updateHospitalTypeForm.value.status.trim();
		let hospitalTypeId = this.updateHospitalTypeForm.value.hospitalTypeId;

		if (
			!description ||
			!name ||
			!status ||
			!hospitalTypeId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.hospitalTypeService
			.updateHospitalType({
				description,
				name,
				status,
				hospitalTypeId
			} as HospitalType)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateHospitalTypeForm.reset(
							{
								"name": "",
								"description": "",
								"status": "",
								"hospitalTypeId": ""
							}
						);
						document.getElementById("updateHospitalTypeModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openHospitalTypeEditDelete(content: any, hospitalTypeId: any): void {
		this.deleteHospitalTypeId = hospitalTypeId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	deleteHospitalType(): void {

		let hospitalTypeId = this.deleteHospitalTypeId
		if (!hospitalTypeId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.hospitalTypeService.deleteHospitalType({ hospitalTypeId } as HospitalType).subscribe(
			(data) => {
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteHospitalTypeModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}
	
	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getHospitalType();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}


}
