<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">User Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10">
					<div class="card-body pt-9 pb-0">
						<div class="d-flex flex-wrap flex-sm-nowrap">
							<div class="me-7 mb-4">
								<div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
									<img src="{{userById.profile_picture}}" *ngIf="userById.profile_picture" alt="image" />
									<img src="assets/media/avatars/blank.png" *ngIf="!userById.profile_picture" alt="image" />
								</div>
							</div>
							<div class="flex-grow-1">
								<div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
									<div class="d-flex flex-column">
										<div class="d-flex align-items-center mb-2">
											<a href="javascript:;" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{userById.first_name}} {{userById.last_name}}</a>
										</div>
										<div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
											<a href="javascript:;" *ngIf="userById.location" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
											<i class="ki-duotone ki-geolocation fs-4 me-1">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>{{userById.location}}</a>
											<a *ngIf="userById.email" href="javascript:;" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
											<i class="ki-duotone ki-sms fs-4 me-1">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>{{userById.email}}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5 active" href="user_view/{{userById.id}}">Overview</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5" href="user_blood_request/{{userById.id}}">Blood Requests</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5" href="user_appointments/{{userById.id}}">Appointments</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
					<div class="card-header cursor-pointer">
						<div class="card-title m-0">
							<h3 class="fw-bold m-0">Profile Details</h3>
						</div>
					</div>
					<div class="card-body p-9">
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">User Name</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.user_name}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Phone</label>
							<div class="col-lg-8 fv-row">
								<span class="fw-semibold text-gray-800 fs-6">{{userById.phone}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Date of Birth</label>
							<div class="col-lg-8">
								<a href="javascript:;" class="fw-semibold fs-6 text-gray-800 text-hover-primary">{{userById.date_of_birth}}</a>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Blood Group</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.blood_group}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">About</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.about}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Gender</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.gender}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Created Date</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.date_filter}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
