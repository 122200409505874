<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Blood Request Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="startcont">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				
				<div class="card mb-5 mb-xl-10">
					<div class="card-body p-9">
					 <div class="row border-sides-1">
					  <div class="col-sm-6 cols-sides-1">
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">User Id</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.first_name}} {{bloodRequestDetails.last_name}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Blood Type</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.blood_type}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Phone</label>
							<div class="col-lg-8 fv-row">
								<span class="fw-semibold pddi-border text-gray-800 fs-6">{{bloodRequestDetails.phone_number}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Patient Name</label>
							<div class="col-lg-8">
								<span class="fw-semibold pddi-border text-gray-800 fs-6">{{bloodRequestDetails.patient_name}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Blood Group</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.blood_group}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Required Date</label>
							<div class="col-lg-8">
								<span class="fw-semibold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.required_date}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Units</label>
							<div class="col-lg-8">
								<span class="fw-semibold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.units}}</span>
							</div>
						</div>
						<div class="row sidegap-bords">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Location</label>
							<div class="col-lg-8">
								<span class="fw-semibold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.location}}</span>
							</div>
						</div>
					  </div>
					  <div class="col-sm-6">
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Critical Level</label>
							<div class="col-lg-8">
								<span class="fw-semibold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.is_critical}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Notes</label>
							<div class="col-lg-8">
								<span class="fw-semibold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.notes}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Status</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.status}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Document</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">
									
									<img alt="Pic" [src]="bloodRequestDetails.document" id="imgPlacement" [hidden]="!bloodRequestDetails.document" width="50" height="50" /></span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Latitude</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.latitude}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Longitude</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.longitude}}</span>
							</div>
						</div>
						<div class="row sidegap-bord">
							<label class="col-lg-4 fw-semibold pddi-border text-muted">Date Filter</label>
							<div class="col-lg-8">
								<span class="fw-bold pddi-border fs-6 text-gray-800">{{bloodRequestDetails.date_filter}}</span>
							</div>
						</div>
						
						</div>
					</div>
					</div>
			</div>
			<div class="card mb-5 mb-xl-10">
				<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
					<div class="post d-flex flex-column-fluid" id="kt_post">
						<div id="kt_content_container" class="container-xxl">
							<div class="py-4">
								<h2>Donors List</h2>
								<div class="row scroll-side-1" *ngIf="donorList.length != 0">
									<div class="col-3" *ngFor="let user of donorList;">
										<div class="card donlist">
											<div class="donlistimg"><img src="{{user.profile_picture}}" width="100" height="100" /></div>
											<h3>{{user.first_name}} {{user.last_name}}</h3>
											<p>{{user.location}}</p>
										</div>
									</div>

								</div>
								<div class="row scroll-side-1" *ngIf="donorList.length == 0">
									<div class="col-3">
											<p>No Record Found</p>
									</div>

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="card mb-5 mb-xl-10">
				<div class="content pb-5 d-flex flex-column flex-column-fluid" id="kt_content">
					<div class="post d-flex flex-column-fluid" id="kt_post">
						<div id="kt_content_container" class="container-xxl">
							<div class="py-4">
								
								<h2>Review</h2>
								<div class="card mb-5 mt-7 mb-xxl-8" *ngIf="reviewList?.length != 0">
    
									<div class="card-body pb-0" *ngFor="let user of reviewList;">
										
										<div class="d-flex align-items-center mb-5">
											
											<div class="d-flex align-items-center flex-grow-1">
												
												<div class="symbol img-80 me-5">
													<img src="{{user.profile_picture}}" alt="">   
													  
												</div>
												
												<div class="d-flex flex-column">
													<a class="text-gray-900 text-hover-primary fs-6 fw-bold">{{user.first_name}} {{user.last_name}}</a>
													
													<div class="rating">
														
														<div class="rating-label checked">
															<i class="ki-duotone ki-star fs-6" *ngFor="let item of [1].constructor(user.rating); let i = index"></i>
															</div>
													</div>
													<span class="text-gray-500 fw-bold">{{user.created | date :'medium'}}</span>
												</div>
												
											</div> 
										</div>
										<div class="mb-7">
											
											<div class="text-gray-800 mb-5">
												{{user.review}}
											</div>
										 </div>
									  </div>
								</div>
								<div class="mb-5 mt-7 mb-xxl-8" *ngIf="reviewList?.length == 0">
									<p>No Record Found</p>
								 </div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-5 mb-xl-10">
				<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
					<div class="post d-flex flex-column-fluid" id="kt_post">
						<div id="kt_content_container" class="container-xxl">
							<div class="py-4">
								<h2>Chat</h2>
								<div class="s-chatting" *ngIf="chatList?.length != 0">	
								<div class="card mt-7" id="kt_chat_messenger">
									<div class="card-body" id="kt_chat_messenger_body">
										<!--begin::Messages-->
										<div style="max-height: 350px;" data-kt-scroll-offset="5px" class="scroll-y me-n5 pe-5 h-300px h-lg-auto">
								 
									<div class="start-chatting">
									<!--begin::Message(in)-->
									<div class="d-flex mb-5" *ngFor="let user of chatList;" [ngClass]="user.sent_from == bloodRequestDetails.user_id ? 'justify-content-end' : 'justify-content-start'">
										<div class="d-flex flex-column align-items-start">
											<div class="d-flex align-items-center mb-2">
												<div class="symbol  img-80 symbol-circle "><img alt="Pic" src="{{user.send_from_profile_picture}}"></div>
													<div class="ms-3">
														<a class="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{{user.send_from_f_name}} {{user.send_from_l_name}}</a>
														<span class="text-muted fs-7 mb-1">{{user.date_filter}}</span>
													</div>
												 
											</div>
											<div class="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
												{{user.message}}</div>
										</div>
									</div>
									
									<!--begin::Message(out)-->
									
									 </div>
									
								</div>	
										<!--end::Messages-->
									</div>
								</div>
							</div>
								<div class="s-chatting"  *ngIf="chatList?.length == 0"><p>No Record Found</p></div>
							</div>
						</div>
					</div>
			    </div>
			</div>
			 
			<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
				<a href="blood_request_list" class="btn btn-primary">Back</a>
			</div>
		</div>
	</div>
</div>
